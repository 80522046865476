import React from 'react';

const QuickClamps = () => {
  return (
    <div className="md:mt-25">
      <div className="text-3xl md:ml-10 text-center md:text-left font-bold mt-8">
        <h1>Quick Clamps</h1>
        <hr className="border-t-2 border-gray-200 my-3 md:w-[95%] " />
      </div>
      <div className="md:flex items-center md:items-start">
        <img
          className="mx-auto md:float-left md:m-10 ml-16"
          src="/adoniNew/products/p19.jpg"
          alt=""
        />

        <div className="md:flex-1 p-4 ">
          <p className="text-justify float-left md:m-10 font-sans text-base leading-7 text-[#444] text-gray-700">
            adoniTech has developed unique solution for JOB clamping.
            The clamp as shown in the photograph can be used to clamp work pieces from 0-60 mm height.
            Clamping is faster and more rigid than the conventional means.
            One does not waste time searching for clamping elements.
            Absence of loose parts makes the clamp very handy and easy to handle.
            These type of clamps give more contact area (as against line contact in conventional clamping)
            and high clamping force.Clamping jaws always remain parallel to the Job the stud always remain
            perpendicular to the bed, hence clamping is very rigid. The clamp is suitable for holding rough,
            machined, round, ground, taper Surfaces.
            Quick clamps<br />
            The company provides kits for clamping for slot sizes of 12, 14, 16, 18, 20, 24, 28, 30, 36 and 42 mm.
            Clamping kits along with extension kits can clamp jobs till height 0 - 300 or more.
            Clamps are most suitable for HMT-M1TR, BFW, and Cooper machines and for Die Holding in Presses.
          </p>
        </div>
      </div>
      <div className="">
        <h1 className="text-justify font-bold md:ml-10 mt-10 text-2xl font-sans text-base leading-7 text-[#444] text-lg tracking-wide">
          PRODUCT DETAILS
        </h1>
        &nbsp;
        <div className="xl:flex justify-between">
          <div className="md:ml-60 md:mt-7 mb-3">
            <img
              className="md:max-w-[500px] h-auto"
              src="/adoniNew/products/p20.jpg"
              alt=""
            />
          </div>
          <div className="md:mr-60">
            <img
              className="md:max-w-[600px] h-auto"
              src="/adoniNew/products/p21.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
      <div>
        <p className="text-justify md:ml-10 ml-3 font-sans leading-7 text-[#444]">
          <strong className=" ">Standard clamping kits are available with</strong>
          <div className="text-sm">
            2 clamps, + 2 Extension Kits 1 + 2 Extension Kit 2.
            <br /> Ordering Information = Standard Clamping Kit - XX
            <br /> Where XX = Slot Size as above.
            <br />
          </div>
          <strong>Features:</strong>
          <div className="text-sm">
            ▪ No Loose Parts
            <br />
            ▪ Parallel Clamping Faces.
            <br />
            ▪ Low Surface Pressures
            <br />
            ▪ Faster Clamping
            <br />
            ▪ Rigid clamping
            <br />
            ▪ Clamping surfaces have specific textures as related to various operations.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;a) for circular job
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;b) Ground Surface
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;c) Casting surface
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;d) General Machined
            <br />
          </div>
          <strong>Time Savers</strong>
          <div className="text-sm mb-10">
            ▪ Easy to locate and find. (Monoblock)
            <br />
            ▪ Faster clamping process.
            <br />
            ▪ Permits heavier cuts / operations due to rigid clamping.

          </div>
        </p>
      </div>
    </div>
  );
}

export default QuickClamps;
