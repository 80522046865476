import React, { Children } from "react";
import TableData from "./table/Table";
import { FaSquareArrowUpRight } from "react-icons/fa6";

const MiniSeries = () => {
  const DIMENSIONcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Thread",
      dataIndex: "thread",
      key: "thread",
    },
    {
      title: "A",
      dataIndex: "a",
      key: "a",
    },
    {
      title: "B",
      dataIndex: "b",
      key: "b",
    },
    {
      title: "C",
      dataIndex: "c",
      key: "c",
    },
    {
      title: "D",
      dataIndex: "d",
      key: "d",
    },
    {
      title: "E",
      dataIndex: "e",
      key: "e",
    },
    {
      title: "F",
      dataIndex: "f",
      key: "f",
    },
    {
      title: "G",
      dataIndex: "g",
      key: "g",
    },
    {
      title: "H",
      dataIndex: "h",
      key: "h",
    },
  ];
  const DIMENSIONdata = [
    {
      key: "1",
      model: "AC 8-6-F",
      thread: "M 8x1.0",
      a: "50",
      b: "38",
      c: "6.6",
      d: "2.8",
      e: "6",
      f: "33",
      g: "11",
      h: "3",
    },
    {
      key: "2",
      model: "AC 10-5-F",
      thread: "M 10x1.0",
      a: "37.7",
      b: "27.7z",
      c: "8.6",
      d: "3",
      e: "6",
      f: "22.9",
      g: "12.7",
      h: "3",
    },
    {
      key: "3",
      model: "AC 10-8-F",
      thread: "M 10x1.0",
      a: "57",
      b: "43",
      c: "8.6",
      d: "3",
      e: "6",
      f: "38",
      g: "2.7",
      h: "3",
    },
    {
      key: "4",
      model: "AC 12-10-F",
      thread: "M 12x1.0",
      a: "69.5",
      b: "50",
      c: "10.3",
      d: "3",
      e: "9.5",
      f: "45.4",
      g: "14",
      h: "4",
    },
    {
      key: "5",
      model: "AC 14-12-M",
      thread: "M 14x1.4",
      a: "102.3",
      b: "76",
      c: "12",
      d: "4",
      e: "14.3",
      f: "67",
      g: "19",
      h: "5",
    },
  ];
  const PERFORMANCEcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Stroke",
      dataIndex: "stroke",
      key: "stroke",
    },
    {
      title: "Energy",
      children: [
        {
          title: "Nm/Stroke",
          dataIndex: "energy",
          key: "energy",
        },
      ],
    },
    {
      title: "Absorbation",
      children: [
        {
          title: "Nm/h",
          dataIndex: "absorbation",
          key: "absorbation",
        },
      ],
    },
    {
      title: "Effective Mass(KG)",
      children: [
        {
          title: "Very Soft",
          dataIndex: "verySoft",
          key: "verySoft",
        },
        {
          title: "Soft",
          dataIndex: "soft",
          key: "soft",
        },
        {
          title: "Hard",
          dataIndex: "hard",
          key: "hard",
        },
        {
          title: "Wt.GM",
          dataIndex: "wt",
          key: "wt",
        },
      ],
    },
  ];

  const PERFORMANCEdata = [
    {
      key: "1",
      model: "AC 8-6-F",
      stroke: "6",
      energy: "2",
      absorbation: "8,800",
      verySoft: "0.5-2.0",
      soft: "2.0-6.0",
      hard: "2.0-6.0",
      wt: "11",
    },
    {
      key: "2",
      model: "AC 10-5-F",
      stroke: "5",
      energy: "3",
      absorbation: "10,800",
      verySoft: "1.0-3.0",
      soft: "3.0-7.0",
      hard: "3.0-7.0",
      wt: "14",
    },
    {
      key: "3",
      model: "AC 10-8-F",
      stroke: "8",
      energy: "4",
      absorbation: "15,200",
      verySoft: "2.0-4.0",
      soft: "4.0-9.0",
      hard: "4.0-9.0",
      wt: "20",
    },
    {
      key: "4",
      model: "AC 12-10-F",
      stroke: "10",
      energy: "5",
      absorbation: "17,640",
      verySoft: "5.0-10.0",
      soft: "10.0-30.0",
      hard: "10.0-30.0",
      wt: "31.5",
    },
    {
      key: "5",
      model: "AC 14-12-M",
      stroke: "15",
      energy: "15",
      absorbation: "30,000",
      verySoft: "8.0-50",
      soft: "50.0-100.0",
      hard: "50.0-100.0",
      wt: "80",
    },
  ];
  return (
    <>
      <h2 className="text-center text-4xl mt--4 font-bold text-slate-600 ">
        Industrial Shock Absorbers
      </h2>
      <hr className="mt-6 border" />
      <div className="mt-10  md:flex md:items-center md:justify-around">
        <img src="/adoniNew/home/product/p1.jpg" alt="" />
        <div>
          <ul style={{ listStyleType: "disc" ,marginLeft: "2rem",marginTop:"1rem"  }} className="text-xl ">
            <li>Two locknuts inclusive</li>
            <li>Hydraulic Hardness fixed type</li>
            <li>Spring Return</li>
            <li>Ureathane striker Cap</li>
            <li>Special Anticorrosive Finish</li>
            <li>Hardened and Polished Rod</li>
            <li>special Viton Seals</li>
          </ul>
        </div>
      </div>
      <hr className="my-4 border" />
      <h2 className="my-8 text-center text-2xl">DIMENSIONS IN MILLIMETERS</h2>
      <TableData columns={DIMENSIONcolumn} data={DIMENSIONdata} />
      <h2 className="my-8 text-center text-2xl">PERFORMANCE TABLE</h2>
      <TableData columns={PERFORMANCEcolumn} data={PERFORMANCEdata} />
      <h2 className="my-8 text-center text-2xl">DETAIL BROCHURE</h2>
      <div className="relative flex justify-center">
        <img
          className="border-8 border-black"
          src="/adoniNew/home/series/miniseries-adonitech.png"
        />
        <a
          href="https://drive.google.com/file/d/1ULnav6pNmuSvpgOp9POc92u8JQFqkWQs/view"
          className="absolute left-[80%] top-5 text-2xl duration-300 hover:scale-150 hover:text-black "
        >
          <FaSquareArrowUpRight className=" bg-slate-500" />
        </a>
      </div>
    </>
  );
};

export default MiniSeries;
