import React from 'react';

const VaccumSuctionCups = () => {
  return (
    <div className="md:mt-30 mt-10 ml-3 mr-3">
      <h1 className="text-3xl font-bold  text-center md:text-left  md:ml-10">Vacuum Suction Cups</h1>
      <hr className="border-t-2  md:ml-10  mt-7 border-gray-200 my-3 md:w-[95%]" />
      <div className="md:mr-10">
        <p className="md:ml-10 mt-10 text-justify">
          Our PU Suction cups are widely used in automated automobile assembly plants, labeling, packaging, electronic components, material handling, and many other industrial applications where pick and place methods are applied. We can also supply customers' standard designs from your supplied drawing.
        </p>
        <p className="md:ml-10 text-justify">
          T&K manufactures High-Quality Suction Cups in polyurethane material. Our polyurethane suction cups possess outstanding engineering properties such as high wear and tear resistance, high tensile strength, and good resilience with long working hours. Our suction cups are produced by high precision machines and under strict quality control. T&K suction cups have a unique process of bonding different hardnesses which means the suction cups have a firm body with soft lips. This makes AdoniTech suction cups stable, durable, with excellent sealing properties.
        </p>
        <p className="md:ml-10 text-justify">
          Our range of 2.5 bellow suction cups has a 60 shore hardness and 30/60 shore hardness combination with soft lips, which means the suction cups are combined with two (2) hardnesses. Our 1.5 bellow and Flat suction cups are with 60 Shore Hardness.
        </p>
        <p className="md:ml-10 text-justify">
          We can offer to design your existing suction cups for your own application, in your own logo colors, and your required suction hardness. In comparison, our polyurethane suction cups will last longer than the cups made from rubber or other vulcanized or polymerized material. The feel of the material will be mainly influenced by the resilience of the material, with a higher resilience elastomeric feeling more springy in hand. It has better flex and resilience properties than the conventional system as used on other cups.
        </p>
      </div>
      <div className="relative flex justify-center mt-5 ">
        <div className="text-center">
          <h1 className="tracking-wide text-2xl mb-4 bg-gray-100 text-gray-500 p-2 rounded-lg w-64 mx-auto">DETAIL BROCHURE</h1>
        </div>
      </div>
      <div className="md:flex justify-center md:items-center mt-5 ">
        <iframe
          title="PDF Viewer"
          className="border-black md:w-[800px] md:h-[1020px]  h-[455px] w-full  mb-10"
          src="https://drive.google.com/file/d/1QvL3tNExChxS8eyET9N0U0S-_3kCruo2/preview"
          frameBorder="0"
          scrolling="auto"
        />

      </div>
    </div>
  );
}


export default VaccumSuctionCups;
