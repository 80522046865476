import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import banner from "../../../../../assets/img/logo/banner1.jpg";

const Header = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowTooltip((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledCard>
      <ContentContainer>
        {/* Left side card with image */}
        <ImageWrapper>
          <div className="relative shadow-lg">
            <img
              src="adoniNew/home/slide1.jpg"
              alt="Adonitech"
              className="h-auto w-full"
            />
          </div>
        </ImageWrapper>

        {/* Right side paragraph */}
        <ContentWrapper>
          <VerticalLine />
          <TextContent>
            <h3>
              Engineering Precision, <br /> Elevating Safety
            </h3>
            <p>
              Adoni tech specializes in researchand development, manufacture,
              testing and solutions of energy absorption andvibration control
              products like hydraulic shock absorbers, viscous dampers, wirerope
              vibration isolators, polyurethane cushions etc. R&D and
              manufacture arebased in Satara in Maharashtra India Currently,
              ADONI TECH has 4 complete product lines andthe leading product
              testing lab in the industry
            </p>

            {/* Button positioned under the text */}
            <Link to="/contact-us">
              <button className="button">Get Started</button>
            </Link>
          </TextContent>
        </ContentWrapper>
      </ContentContainer>
    </StyledCard>
  );
};

// Styled components
const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  max-width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const ImageWrapper = styled.div`
  flex: 1;
  height: 120%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  img {
    width: 100%;
    height: 120%;
    object-fit: cover;
    object-position: right;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  img {
    position: relative;
    z-index: 0;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #d2e8f5;
  // padding: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    // padding: 40px;
    // padding-left: 65px;
  }
`;

const VerticalLine = styled.div`
  border-left: 4px solid #445864;
  height: 50px;
  margin-right: 10px;
  margin-top: 40px;

  @media (min-width: 768px) {
    height: 200px;
    margin-right: 20px;
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.5rem;
    color: #2f3c44;

    @media (min-width: 768px) {
      font-size: 2.5rem;
      margin-top: 20px;
    }
  }

  p {
    font-size: 1rem;
    color: #2f3c44;

    @media (min-width: 768px) {
      font-size: 1.25rem;
    }
  }

  .button {
    display: inline-block;
    padding: 7px 14px;
    border: 2px solid #445864;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    font-size: 1rem;
    cursor: pointer;
    color: black;
    z-index: 1;
    // margin-top: 24px;
    margin-left: -24px;

    @media (min-width: 768px) {
      font-size: 1.2rem;
    }
  }

  .button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #0478bf;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button:hover {
    color: #ffffff;
    border: 1px solid #0478bf;
  }

  .button:hover:before {
    top: -35%;
    background-color: #0478bf;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }

  .button:hover:after {
    top: -45%;
    background-color: #0478bf;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
`;

export default Header;
