import React, { Children } from "react";
import TableData from "./table/Table";
import { FaSquareArrowUpRight } from "react-icons/fa6";

const SmallSeries = () => {
  const DIMENSIONcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Thread",
      dataIndex: "thread",
      key: "thread",
    },
    {
      title: "A",
      dataIndex: "a",
      key: "a",
    },
    {
      title: "B",
      dataIndex: "b",
      key: "b",
    },
    {
      title: "C",
      dataIndex: "c",
      key: "c",
    },
    {
      title: "D",
      dataIndex: "d",
      key: "d",
    },
    {
      title: "E",
      dataIndex: "e",
      key: "e",
    },
    {
      title: "F",
      dataIndex: "f",
      key: "f",
    },
    {
      title: "G",
      dataIndex: "g",
      key: "g",
    },
    {
      title: "H",
      dataIndex: "h",
      key: "h",
    },
    {
      title:"AF",
      dataIndex:"af",
      key:"af"
    }
  ];
  const DIMENSIONdata = [
    {
      key: "1",
      model: "AD 14-10-F",
      thread: "M 14X1",
      a: "84",
      b: "61",
      c: "10.5",
      d: "4",
      e: "10",
      f: "8",
      g: "3.5",
      h: "4",
      af:"19"
    },
    {
      key: "2",
      model: "AD 14-10-M",
      thread: "M 14X1.5",
      a: "84",
      b: "61",
      c: "10.5",
      d: "4",
      e: "10",
      f: "8",
      g: "3.5",
      h: "4",
      af:"19"
    },
    {
      key: "3",
      model: "AD 14-10-U",
      thread: "9/16-18UNF",
      a: "3.3",
      b: "2.4",
      c: "0.4",
      d: "0.2",
      e: "0.4",
      f: "0.3",
      g: "0.1",
      h: "0.2",
      af:"0.7"
    },
    {
      key: "4",
      model: "AD 16-13-F",
      thread: "M 16X1",
      a: "107",
      b: "74",
      c: "10.5",
      d: "6",
      e: "14",
      f: "12",
      g: "3.5",
      h: "5",
      af:"22"
    },
    {
      key: "5",
      model: "AD 16-13 M",
      thread: "M 16X1.5",
      a: "107",
      b: "74",
      c: "10.5",
      d: "6",
      e: "14",
      f: "12",
      g: "3.5",
      h: "5",
      af:"22"
    },
    {
      key: "6",
      model: "AD 20-20M",
      thread: "M 20X1.5",
      a: "128",
      b: "88",
      c: "10.5",
      d: "6",
      e: "14",
      f: "12",
      g: "5.5",
      h: "6",
      af:"26"
    },
    {
      key: "7",
      model: "AD 20-20U",
      thread: "3/4-16UNF",
      a: "5.0",
      b: "3.5",
      c: "0.4",
      d: "0.2",
      e: "0.6",
      f: "0.5",
      g: "0.2",
      h: "0.2",
      af:"1.0"
    },
    {
      key: "8",
      model: "AD 20-50 M",
      thread: "M 20X1.5",
      a: "229.0",
      b: "160.0",
      c: "10.5",
      d: "6.0",
      e: "14.0",
      f: "12.0",
      g: "5.5",
      h: "6.0",
      af:"26.0"
    },
    {
      key: "9",
      model: "AD 22-20M",
      thread: "M 22X1.5",
      a: "128",
      b: "88",
      c: "10.5",
      d: "6",
      e: "14.0",
      f: "12.0",
      g: "5.5",
      h: "6",
      af:"26"
    },
    {
      key: "10",
      model: "AD 25-25-M",
      thread: "M25X1.5",
      a: "151",
      b: "108",
      c: "12",
      d: "8",
      e: "16",
      f: "12",
      g: "5.5",
      h: "7",
      af:"30"
    },
    {
      key: "11",
      model: "AD 25-25-U",
      thread: "1-12UNF",
      a: "5.9",
      b: "4.3",
      c: "0.5",
      d: "0.3",
      e: "0.6",
      f: "0.5",
      g: "0.2",
      h: "0.3",
      af:"1.2"
    },
    {
      key: "12",
      model: "AD 26-25-M",
      thread: "M26X1.5",
      a: "151",
      b: "108",
      c: "12",
      d: "8",
      e: "16",
      f: "12",
      g: "5.5",
      h: "7",
      af:"30"
    },
    {
      key: "13",
      model: "AD 25-40-M",
      thread: "M25X1.5",
      a: "189",
      b: "129",
      c: "12",
      d: "8",
      e: "16",
      f: "12",
      g: "5.5",
      h: "7",
      af:"30"
    },
    {
      key: "14",
      model: "AD 25-40 U",
      thread: "1-12 UNF",
      a: "7.4",
      b: "5.1",
      c: "0.5",
      d: "0.3",
      e: "0.6",
      f: "0.5",
      g: "0.2",
      h: "0.3",
      af:"1.2"
    },
   
  ];
  const PERFORMANCEcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Stroke",
      dataIndex: "stroke",
      key: "stroke",
    },
    {
      title: "Energy",
      children: [
        {
          title: "Nm/Stroke",
          dataIndex: "energy",
          key: "energy",
        },
      ],
    },
    {
      title: "Absorbation",
      children: [
        {
          title: "Nm/h",
          dataIndex: "absorbation",
          key: "absorbation",
        },
      ],
    },
    {
      title: "Effective Mass(KG)",
      children: [
        {
          title: "Nm/h",
          dataIndex: "nmh",
          key: "nmh",
        },
        {
          title: "Min. Kg.",
          dataIndex: "minkg",
          key: "minkg",
        },
      ],
    },
    {
      title:"Spring Force",
      children:[
        {
          title:"Min. N",
          dataIndex:"minN",
          key:"minN"
        },
        {
          title:"Max. N",
          dataIndex:"maxN",
          key:"maxN"
        }
      ]
    },
    {
      title:"Wt. GM.",
      dataIndex:"wt",
      key:"wt"
    }
  ];

  const PERFORMANCEdata = [
  {
    key: "1",
    model: "AD 14-10 FMU 10",
    stroke: "10",
    energy: "5",
    absorbation: "15000",
    nmh: "0.7",
    minkg: "55",
    minN:"4",
    maxN:"8",
    wt:"65"
  },
  {
    key: "2",
    model: "AD 16-13 FM",
    stroke: "13",
    energy: "17",
    absorbation: "25000",
    nmh: "1.5",
    minkg: "160",
    minN:"4",
    maxN:"11",
    wt:"105"
  },
  {
    key: "3",
    model: "AD 20-20 MU",
    stroke: "20",
    energy: "25",
    absorbation: "35000",
    nmh: "2",
    minkg: "220",
    minN:"12",
    maxN:"19",
    wt:"90"
  },
  {
    key: "4",
    model: "AD 20-50 MU",
    stroke: "50",
    energy: "60",
    absorbation: "60000",
    nmh: "10",
    minkg: "400",
    minN:"6",
    maxN:"17",
    wt:"219"
  },
  {
    key: "5",
    model: "AD 22-22 M",
    stroke: "20",
    energy: "25",
    absorbation: "35000",
    nmh: "2",
    minkg: "220",
    minN:"12",
    maxN:"9",
    wt:"90"
  },
  {
    key: "6",
    model: "AD 25-25 MU",
    stroke: "25",
    energy: "85",
    absorbation: "70000",
    nmh: "10",
    minkg: "1500",
    minN:"12",
    maxN:"28",
    wt:"340"
  },
  {
    key: "7",
    model: "AD 26-25 M",
    stroke: "25",
    energy: "85",
    absorbation: "70000",
    nmh: "10",
    minkg: "1500",
    minN:"12",
    maxN:"28",
    wt:"345"
  },
  {
    key: "8",
    model: "AD 25-40 MU",
    stroke: "40",
    energy: "130",
    absorbation: "117000",
    nmh: "10",
    minkg: "2100",
    minN:"6",
    maxN:"17",
    wt:"395"
  }
  ];
  return (
    <>
      <h2 className="text-center text-4xl font-bold text-slate-600 ">
        Industrial Shock Absorbers
      </h2>
      <hr className="mt-6 border" />
      <div className="mt-10 md:flex md:items-center md:justify-around">
        <img src="/adoniNew/products/smallseries1.jpg" />
        <div>
          <ul style={{ listStyleType: "disc" }} className="text-xl">
            <li>Two locknuts inclusive</li>
            <li>Hydraulic Hardness fixed type</li>
            <li>Spring Return</li>
            <li>Ureathane striker Cap</li>
            <li>Special Anticorrosive Finish</li>
            <li>Hardened and Polished Rod</li>
            <li>special Viton Seals</li>
            <li>Nitrided wear parts for higher life</li>
            <li>Adjustment axialy at Bottom</li>
            <li>Special versions available on request</li>
          </ul>
        </div>
      </div>
      <hr className="my-4 border" />
      <h2 className="my-8 text-center text-2xl">DIMENSIONS IN MILLIMETERS</h2>
      <TableData columns={DIMENSIONcolumn} data={DIMENSIONdata} />
      <h2 className="my-8 text-center text-2xl">PERFORMANCE TABLE</h2>
      <TableData columns={PERFORMANCEcolumn} data={PERFORMANCEdata} />
      <h2 className="my-8 text-center text-2xl">DETAIL BROCHURE</h2>
      <div className="relative flex justify-center">
        <img
          className="border-8 border-black"
          src="/adoniNew/home/series/smallseries-adonitech.png"
        />
        <a
          href="https://drive.google.com/file/d/1rso-oPS2F4ON4aCAg7Q65acChtOGwHkT/view"
          className="absolute left-[80%] top-5 text-2xl duration-300 hover:scale-150 hover:text-black "
        >
          <FaSquareArrowUpRight className=" bg-slate-500" />
        </a>
      </div>
    </>
  );
};

export default SmallSeries;
