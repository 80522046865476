// import React from 'react';

// const PrivacyPolicy = () => {
//   return (
//     <div className="bg-purple-800 text-white py-10 mt-20">
//       <div className="container mx-auto px-4">
//         <h1 className="text-3xl font-bold">Privacy Policy</h1>
//         <p className="mt-2">Effective May 1, 2018</p>
//         <div className="bg-white text-black mt-10 p-6 rounded-lg shadow-md">
//           <div className="flex">
//             <div className="w-1/4 pr-4">
//               <ul className="text-lg font-semibold">
//                 <li className="mt-2">Types of information we collect online</li>
//                 <li className="mt-2">Information that may be collected automatically</li>
//                 <li className="mt-2">How we use your information</li>
//                 <li className="mt-2">Information we share</li>
//                 <li className="mt-2">Children privacy</li>
//                 <li className="mt-2">Your privacy choices</li>
//               </ul>
//             </div>
//             <div className="w-3/4 pl-4">
//               <h2 className="text-2xl font-bold">Types of information we collect online</h2>
//               <p className="mt-2">
//                 The types of Personal Data that we may collect while you use the Updevision Site are described
//                 in this section and include both information that you provide to us and information that we collect
//                 automatically when you use the *** Site.
//               </p>
//               <p className="mt-2">
//                 For purposes of this Privacy Notice, “Personal Data” means information that identifies you or that could
//                 reasonably be used to identify you. Examples of Personal Data include name, address, telephone
//                 number, and email address.
//               </p>
//               <h3 className="text-xl font-semibold mt-4">Information You Provide</h3>
//               <p className="mt-2">
//                 You generally do not have to register for a service or program to receive much of the information
//                 available through *** Sites. However, some of our content is available only to registered or identified users
//                 and will require you to set up a profile or provide specific information about yourself in order to provide
//                 you the service.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PrivacyPolicy;



import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className=" bg-white  mt-18">
            <div className=" mx-auto ">
                <div className='   bg-purple-800  text-white px-40 text-center rounded-t py-12 rounded-[00px] '>
                    <h1 className="text-3xl font-bold">Privacy Policy</h1>
                    <p className="mt-2">  Welcome to 5TG-SBM, an HRM CRM application designed to help employers manage their workforce effectively. At 5TG-SBM, we prioritize your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your information.
                    </p>
                </div>
                <div className="bg-white text-black mt-10 p-6 rounded-lg shadow-md">
                    <div className="flex">
                        <div className="w-1/4 pr-4">
                            <ul className="text-lg font-semibold">
                                <li className="mt-2">Introduction</li>
                                <li className="mt-2">Information We Collect</li>
                                <li className="mt-2">Purpose of Data Collection</li>
                                <li className="mt-2">How We Collect Data</li>
                                <li className="mt-2">User Consent</li>
                                <li className="mt-2">Data Security</li>
                                <li className="mt-2">Data Sharing</li>
                                <li className="mt-2">User Rights</li>
                                <li className="mt-2">Changes to This Privacy Policy</li>
                                <li className="mt-2">Contact Us</li>
                                <li className="mt-2">Conclusion</li>
                            </ul>
                        </div>
                        <div className="w-3/4 pl-4">

                            <h2 className="text-2xl font-bold mt-4">Information We Collect</h2>
                            <p className="mt-2">
                                <strong>Location Information</strong><br />
                                Precise Location: We collect precise location data to track the real-time location of employees on the field. <br />
                                Approximate Location: We collect approximate location data for situations where precise location is not required. <br />
                                Background Location: We collect location data in the background to ensure continuous tracking even when the app is not actively in use.
                            </p>
                            <p className="mt-2">
                                <strong>Photos</strong><br />
                                We collect and store photos that users capture using the app. These photos are stored in the private space of the app and are not accessible from external directories.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">Purpose of Data Collection</h2>
                            <p className="mt-2">
                                The data we collect is essential for the following purposes: <br />
                                <strong>Employee Tracking:</strong> Employers use location data to track their employees' whereabouts on the field. This helps in managing field operations efficiently. <br />
                                <strong>Internal Use Only:</strong> All collected data is processed on our secure servers and displayed only to the employer's admin. We do not process or share this data with any third parties.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">How We Collect Data</h2>
                            <p className="mt-2">
                                We collect data through the permissions granted by the users: <br />
                                <strong>Precise Location Permission:</strong> To collect accurate location data. <br />
                                <strong>Approximate Location Permission:</strong> To gather general location data when precise tracking is unnecessary. <br />
                                <strong>Background Location Permission:</strong> To continuously track location data even when the app is not in active use.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">User Consent</h2>
                            <p className="mt-2">
                                Before collecting any data, we request user permission and provide clear information about why these permissions are necessary. Users are educated about the importance of each permission through in-app prompts and documentation.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">Data Security</h2>
                            <p className="mt-2">
                                We are committed to protecting your personal information. Data is stored securely on our servers and is only accessible to authorized personnel. Photos and location data are stored in the private space of the app and are not accessible from external directories.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">Data Sharing</h2>
                            <p className="mt-2">
                                We do not share your personal data with third parties. All data collected is used exclusively for the purposes stated in this Privacy Policy and is accessible only to the employer's admin within the organization.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">User Rights</h2>
                            <p className="mt-2">
                                Users have the right to: <br />
                                <strong>Access:</strong> Request access to the data we have collected. <br />
                                <strong>Correction:</strong> Request corrections to any inaccurate or incomplete data. <br />
                                <strong>Deletion:</strong> Request the deletion of their data, subject to company policies and legal requirements.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">Changes to This Privacy Policy</h2>
                            <p className="mt-2">
                                We may update this Privacy Policy from time to time. Any changes will be communicated to users through in-app notifications and will be effective immediately upon posting.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">Contact Us</h2>
                            <p className="mt-2">
                                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at Contact@5techg.com.
                            </p>
                            <h2 className="text-2xl font-bold mt-4">Conclusion</h2>
                            <p className="mt-2">
                                We value your privacy and strive to protect your personal information. By using 5TG-SBM, you consent to the collection and use of your data as described in this Privacy Policy.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
