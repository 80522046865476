import React, { Children } from "react";
import TableData from "./table/Table";
import { FaSquareArrowUpRight } from "react-icons/fa6";

const MediumSeries = () => {
  const DIMENSIONcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Thread",
      dataIndex: "thread",
      key: "thread",
    },
    {
      title: "A",
      dataIndex: "a",
      key: "a",
    },
    {
      title: "B",
      dataIndex: "b",
      key: "b",
    },
    {
      title: "C",
      dataIndex: "c",
      key: "c",
    },
    {
      title: "D",
      dataIndex: "d",
      key: "d",
    },
    {
      title: "E",
      dataIndex: "e",
      key: "e",
    },
    {
      title: "F",
      dataIndex: "f",
      key: "f",
    },
    {
      title: "G",
      dataIndex: "g",
      key: "g",
    },
    {
      title: "H",
      dataIndex: "h",
      key: "h",
    },
  ];
  const DIMENSIONdata = [
    {
      key: "1",
      model: "AD 30-25 M",
      thread: "M 30X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "2",
      model: "AD 33-25 M",
      thread: "M 33X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "3",
      model: "AD 33-25 U",
      thread: "1 1/4-12U",
      a: "6.2",
      b: "3.7",
      c: "0.5",
      d: "0.4",
      e: "1.1",
      f: "0.3",
      g: "1.0",
      h: "0.3",
    },
    {
      key: "4",
      model: "AD 36-25 M",
      thread: "M 36X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "5",
      model: "AD 37-25 M",
      thread: "M 37X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "6",
      model: "AD 30-50 M",
      thread: "M 30X1.5",
      a: "207",
      b: "120",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "50",
      h: "7",
    },
    {
      key: "7",
      model: "AD 33-50 M",
      thread: "M 33X1.5",
      a: "207",
      b: "120",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "50",
      h: "7",
    },
    {
      key: "8",
      model: "AD 36-50 M",
      thread: "M 36X1.5",
      a: "207",
      b: "120",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "50",
      h: "7",
    },
    {
      key: "9",
      model: "AD 37-50 M",
      thread: "M 37X1.5",
      a: "207",
      b: "120",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "50",
      h: "7",
    },
    {
      key:"10",
      model:"AD 37-75 M",
      thread:"M 37X1.5",
      a:"262",
      b:"145",
      c:"12",
      d:"10",
      e:"28",
      f:"8",
      g:"75",
      h:"7"
    }
  ];
  const PERFORMANCEcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Stroke",
      dataIndex: "stroke",
      key: "stroke",
    },
    {
      title: "Energy",
      children: [
        {
          title: "Nm/Stroke",
          dataIndex: "energy",
          key: "energy",
        },
      ],
    },
    {
      title: "Absorbation",
      children: [
        {
          title: "Nm/h",
          dataIndex: "absorbation",
          key: "absorbation",
        },
      ],
    },
    {
      title: "Effective Mass(KG)",
      children: [
        
        {
          title: "Min. Kg.",
          dataIndex: "minkg",
          key: "minkg",
        },
        {
          title: "Max. Kg.",
          dataIndex: "maxkg",
          key: "maxkg",
        }
      ],
    },
    {
      title:"Spring Force",
      children:[
        {
          title:"Min. N",
          dataIndex:"minN",
          key:"minN"
        },
        {
          title:"Max. N",
          dataIndex:"maxN",
          key:"maxN"
        }
      ]
    },
    {
      title:"Wt. GM.",
      dataIndex:"wt",
      key:"wt"
    }
  ];

  const PERFORMANCEdata = [
   {
      key: "1",
      model: "AD 30-25 M",
      stroke: "25",
      energy:"120",
      absorbation:"82,000",
      minkg:"10",
      maxkg:"1,800",
      minN:"40",
      maxN:"70",
      wt:"640"
   },
   {
      key: "2",
      model: "AD 33-25 M U",
      stroke: "25",
      energy:"120",
      absorbation:"82,000",
      minkg:"10",
      maxkg:"1,800",
      minN:"40",
      maxN:"70",
      wt:"640"
   },
   {
      key: "3",
      model: "AD 36-25 M",
      stroke: "25",
      energy:"120",
      absorbation:"82,000",
      minkg:"10",
      maxkg:"1,800",
      minN:"40",
      maxN:"70",
      wt:"640"
   },
   {
      key: "4",
      model: "AD 37-25 M",
      stroke: "25",
      energy:"120",
      absorbation:"82,000",
      minkg:"10",
      maxkg:"1,800",
      minN:"40",
      maxN:"70",
      wt:"640"
   },
   {
      key: "5",
      model: "AD 30-50 M",
      stroke: "50",
      energy:"250",
      absorbation:"97,000",
      minkg:"13",
      maxkg:"2,700",
      minN:"45",
      maxN:"80",
      wt:"650"
   },
   {
      key: "6",
      model: "AD 33-50 M",
      stroke: "50",
      energy:"250",
      absorbation:"97,000",
      minkg:"13",
      maxkg:"2,700",
      minN:"45",
      maxN:"80",
      wt:"655"
   },
   {
      key: "7",
      model: "AD 36-50 M",
      stroke: "50",
      energy:"250",
      absorbation:"97,000",
      minkg:"13",
      maxkg:"2,700",
      minN:"45",
      maxN:"80",
      wt:"655"
   },
   {
      key: "8",
      model: "AD 37-50 M",
      stroke: "50",
      energy:"250",
      absorbation:"97,000",
      minkg:"13",
      maxkg:"2,700",
      minN:"45",
      maxN:"80",
      wt:"660"
   },
   {
      key: "9",
      model: "AD 37-75 M",
      stroke: "75",
      energy:"380",
      absorbation:"120,000",
      minkg:"13",
      maxkg:"2,700",
      minN:"45",
      maxN:"80",
      wt:"680"
   }
  ];
  return (
    <>
      <h2 className="text-center text-4xl font-bold text-slate-600 ">
        Industrial Shock Absorbers
      </h2>
      <hr className="mt-6 border" />
      <div className="mt-10 md:flex md:items-center md:justify-around">
        <img src="/adoniNew/products/medimumseries1.jpg" alt="mediumseries" />
        <div>
          <ul style={{ listStyleType: "disc" }} className="text-xl">
            <li>Two locknuts inclusive</li>
            <li>Hydraulic Hardness fixed type</li>
            <li>Spring Return</li>
            <li>Ureathane striker Cap</li>
            <li>Special Anticorrosive Finish</li>
            <li>Hardened and Polished Rod</li>
            <li>special Viton Seals</li>
            <li>Adjustment axially at Bottom</li>
            <li>Nitrided wear parts for higher life</li>
            <li>Stainless steel model available</li>
            <li>Special versions available on request</li>
          </ul>
        </div>
      </div>
      <hr className="my-4 border" />
      <h2 className="my-8 text-center text-2xl">DIMENSIONS IN MILLIMETERS</h2>
      <TableData columns={DIMENSIONcolumn} data={DIMENSIONdata} />
      <h2 className="my-8 text-center text-2xl">PERFORMANCE TABLE</h2>
      <TableData columns={PERFORMANCEcolumn} data={PERFORMANCEdata} />
      <h2 className="my-8 text-center text-2xl">DETAIL BROCHURE</h2>
      <div className="relative flex justify-center">
        <img
          className="border-8 border-black"
          src="/adoniNew/home/series/miniseries-adonitech.png"
        />
        <a
          href="https://drive.google.com/file/d/1ULnav6pNmuSvpgOp9POc92u8JQFqkWQs/view"
          className="absolute left-[80%] top-5 text-2xl duration-300 hover:scale-150 hover:text-black "
        >
          <FaSquareArrowUpRight className=" bg-slate-500" />
        </a>
      </div>
    </>
  );
};

export default MediumSeries;
