import React from 'react'
import AKHG1 from "../../../../../../../assets/screen/AKHG1.jpg";
import AKHG2 from "../../../../../../../assets/screen/AKHG2.jpg";
import AKHG3 from "../../../../../../../assets/screen/AKHG3.jpg";
import AKHG4 from "../../../../../../../assets/screen/AKHG4.jpg";
import AKHG5 from "../../../../../../../assets/screen/AKHG5.jpg";
import AKHG6 from "../../../../../../../assets/screen/AKHG6.jpg";
import AKHG7 from "../../../../../../../assets/screen/AKHG7.jpg";
import AKHG8 from "../../../../../../../assets/screen/AKHG8.jpg";
import AKHG9 from "../../../../../../../assets/screen/AKHG9.jpg";
import AKHG10 from "../../../../../../../assets/screen/AKHG10.jpg";
import AKHG11 from "../../../../../../../assets/screen/AKHG11.jpg";

function AKHG() {

    return (
        <div>
            {/* <h1 className='text-center text-3xl  font-sans font-semibold pt-10'>AKHG Series Crane Buffers for Web </h1> */}
            <img className='  mx-auto' src={AKHG1}></img>
            <img className='   mx-auto' src={AKHG2}></img>
            <img className='   mx-auto' src={AKHG3}></img>
            <img className='   mx-auto' src={AKHG4}></img>
            <img className='   mx-auto' src={AKHG5}></img>
            <img className='   mx-auto' src={AKHG6}></img>
            <img className='   mx-auto' src={AKHG7}></img>
            <img className='   mx-auto' src={AKHG8}></img>
            <img className='   mx-auto' src={AKHG9}></img>
            <img className='   mx-auto' src={AKHG10}></img>
            <img className='   mx-auto' src={AKHG11}></img>
        </div>
    )
}

export default AKHG
