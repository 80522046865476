import React from "react";
import banner from "../../../assets/img/logo/banner1.jpg";
import pdf from "../../../assets/Adonitech linkedin post.pdf";

export default function News() {
  return (
    <div>
      <div class="container mx-auto md:px-6">
        <section class="pb-32 pt-24 text-center">
          <h3 class='lg:text-3xl text-gray-600 pt-2 font-semibold pl-2 text-center mt-10' style={{ color: '#0478BF' }}>
            Latest articles
          </h3>

          <div class="flex justify-between gap-10 pt-10">
            {/* Each card */}
            <div class="border border-blue-500 p-6 hover:shadow-lg transition-shadow duration-300 w-1/3 h-[34rem]">
              {/* Inner card for image */}
              <div class="flex justify-center mb-4 border border-blue-200 h-56">
                <div class="relative">
                  <img src="adoni/newsBlog/ask-4.jpg" class="object-cover w-56 h-56" alt="Shock absorbers for Cranes" />
                  <a href="#!" class="absolute inset-0 bg-black bg-opacity-10 transition-opacity duration-300 ease-in-out hover:bg-opacity-25"></a>
                </div>
              </div>

              {/* Content */}
              <div>
                <h5 class="mb-3 text-lg font-bold text-gray-800">Shock Absorbers for Cranes</h5>
                <p class="mb-4 text-gray-600">
                  Adonitech has developed the capacity to design, manufacture, and test shock absorbers for Cranes with a 325 Kilo Joules capacity.
                </p>
                <a href="#!" class="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-blue-700 focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                  Read more
                </a>
              </div>
            </div>

            {/* Repeat for other cards (2, 3, 4) */}
            <div class="border border-blue-500 p-6 hover:shadow-lg transition-shadow duration-300 w-1/3 h-[34rem]">
              {/* Inner card for image */}
              <div class="flex justify-center mb-4 border border-blue-200 h-56">
                <div class="relative">
                <img src="adoni/newsBlog/2.jpeg" class="object-cover w-56 h-56" />
                 <a href="#!">
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98.4%,.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                   </a>
                </div>
              </div>

              {/* Content */}
              <div class="p-3">
              <h5 class="mb-3 text-lg font-bold"> Heavy duty Buffers </h5>

             <p class="mb-4 pb-2">
               The Heavy duty Buffers are damped by a controlled orifices
               and returns to a original position by removal of load
             </p>
                 <a
                href="#!"
                data-te-ripple-init
                data-te-ripple-color="light"
                class="hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700 :shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] :hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] inline-block bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
              >
                Read more
              </a>
              </div>
            </div>

            {/* Continue for Card 3 and Card 4 */}
            <div class="border border-blue-500 p-6 hover:shadow-lg transition-shadow duration-300 w-1/3 h-[34rem]">
              {/* Inner card for image */}
              <div class="flex justify-center mb-4 border border-blue-200 h-56">
                <div class="relative">
                  <img src="adoni/newsBlog/blog3.jpg" class="object-cover w-56 h-56" />
                  <a href="adoni/newsBlog/blog3.pdf" download>
                    <div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98.4%,.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
              </div>

              {/* Content */}
              <div class="p-4">
                <h5 class="mb-3 text-lg font-bold">
                  {" "}
                  औद्योगिक शॉक ऑब्जर्वर{" "}
                </h5>

                <p class="mb-4 pb-2">
                  रेखीय गती किंवा रोटरी गती परी नावाची ऊर्जा विविध
                  उद्योगांमध्ये उद्योगपतींचे शोषण शॉक शोषक आवश्यक आहे....
                </p>
                <a
                  href="adoni/newsBlog/blog3.pdf"
                  download
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700 :shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] :hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] inline-block  bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  Read more
                </a>
              </div>
            </div>

            <div class="border border-blue-500 p-6 hover:shadow-lg transition-shadow duration-300 w-1/3 h-[34rem]">
              {/* Inner card for image */}
              <div class="flex justify-center mb-4 border border-blue-200 h-56">
                <div class="relative">
                  <img src={banner} class="object-cover w-56 h-56" />
                  <a href={pdf} download><div class="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98.4%,.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100"></div>
                  </a>
                </div>
              </div>

              {/* Content */}
              <div class="p-2">
                <h5 class="mb-3 text-lg font-bold">About Our Company</h5>

                <p class="mb-4 pb-2">
                  Learn more about our company's history, mission, and values.
                  Discover how we strive to provide the best solutions for our
                  clients.
                </p>
                <a
                  href={pdf}
                  download
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  class="hover:bg-primary-600 focus:bg-primary-600 active:bg-primary-700 :shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] :hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] :active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] inline-block bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
       </section>
      </div>
    </div> 
  );
}
