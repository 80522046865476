import React from "react";
import { useParams } from "react-router-dom";
import Industrial from "./components/Industrial/Industrial";
import LinearMotions from "./components/LinearMotions/LinearMotions";
import QuickClamps from "./components/QuickClamps/QuickClamps";
import PneumaticRodlessCylinders from "./components/PneumaticRodlessCylinders/PneumaticRodlessCylinders";
import VaccumSuctionCups from "./components/VaccumSuctionCups/VaccumSuctionCups";
import WireRopeIsolators from "./components/WireRopeIsolators/WireRopeIsolators";
import CraneBuffer from "./components/CraneBuffer/CraneBuffer";
import CraneTrays from "./components/CraneTrays/CraneTrays";
import HydraulicFeedRateControllers from "./components/HydraulicFeedRateControllers/HydraulicFeedRateControllers";
const ProductsCategory = () => {
  const { category } = useParams();
  const renderComponent = () => {
    switch (category) {
      case "industrialShockAbsorber":
        return <Industrial />;
      case "linearMotionSlides":
        return <LinearMotions />;
      case "quickClamps":
        return <QuickClamps />;
      case "pneumaticRodlessCylinders":
        return <PneumaticRodlessCylinders />;
      case "vaccumSuctionCups":
        return <VaccumSuctionCups />;
      case "wireRopeIsolators":
        return <WireRopeIsolators />;
      case "craneBuffers":
        return <CraneBuffer />;
      case "cableTrays":
        return <CraneTrays />;
      case "hydraulicFeedRateControllers":
        return <HydraulicFeedRateControllers />;
      default:
        return <div>Select a product category.</div>;
    }
  };

  return <div>{renderComponent()}</div>;
};

export default ProductsCategory;
