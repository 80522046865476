import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import About from "../components/Sections/About/About";
import Event from "../components/Sections/Event/Event";
import SocialLink from "../components/Nav/SocialLink";
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
import Landing from "../screens/Landing";
import Service from "../components/Sections/service/Service";
import Products from "../components/products/Products";
import Facility from "../components/Sections/facility/Facility";
import Pdfsdowload from "../components/Sections/credential/loginuser/pdfsdowload";
import News from "../components/Sections/newsEvent/News";
import ContactUs from "../components/Sections/contact/ContactUs";
import Tabs from "../components/Sections/credential/loginuser/Tabs";
import Career from "../components/career/Career";
import PrivacyPolicy from "../components/Sections/privacy/Privacy";
import ProductsCategory from "../components/Sections/Home/Product copy/ProductsCategory";
import NewContact from "../components/Sections/contact/NewContact";
import CareerS from "../components/Sections/contacts/career/NewContacts";
import NewContacts from "../components/Sections/contacts/career/NewContacts";
import Main3d from "../components/Sections/3dDownload/Main3d";
import FirstScreen from "../components/Sections/Home/FirstScreen/FirstScreen";
import ReactGA from "react-ga4";
function Page() {
  ReactGA.initialize("G-STKPJRD2PX");
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
  return (
    <BrowserRouter>
      {/* <SocialLink /> */}
      <TopNavbar />

      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/About" element={<About />} />
        <Route path="/Service" element={<Service />} />
        <Route path="/products" element={<Products />} />
        <Route path="/facility" element={<Facility />} />
        <Route path="/news-event-blog" element={<News />} />
        <Route path="/contact-us" element={<NewContacts />} />
        <Route path="/credentials" element={<Tabs />} />
        <Route path="/career" element={<Career />} />
        <Route path="/event" element={<Event />}/>
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/product/:category" element={<ProductsCategory />} />
        <Route path="/Downloads" element={<Main3d />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Page;
