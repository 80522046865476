import React from 'react';

const HydraulicFeedRateControllers = () => {
  return (
    <div className="md:flex md:justify-between mt-20">
      <div className="md:w-4/6 p-8 border border-gray-300 shadow-lg mb-10 md:ml-7">
        <h1 className="text-3xl font-bold mb-4">Hydraulic Feed Rate Controllers</h1>
        <hr className="border-t-2 border-gray-400 mt-4  my-3 md:w-[100%]" />
        <p className="text-lg mb-4">
          A recent increase in demand for automation in drilling has evolved into new products. The actuation being mostly pneumatic, the feed rate is controlled by Hydraulic feed rate controllers. The required thrust is generated by controlling the size of an orifice by adjusting a knob in about 300 degrees of rotation from minimum resistance to the maximum resistance offered. The adjustment is infinitely variable over the complete damping range and fine tuning can be done with the help of graduations provided on the speed controller.
        </p>
        <p className="text-lg mb-4">
          Drilling equipment with pneumatic feeds, screw feeds, and are the potential applications of the Hydraulic speed controllers. Generally, the feed rates achieved are 12 - to 40 meters/minute. In special cases, 0.015 to 15 meters/min can be achieved by some special provisions and changes in the resistance mechanism. A return stroke is achieved by the virtue of an inbuilt spring and opening of a non-return valve. AdoniTech also offers reconditioning services to imported speed controllers. For food or glass industries, these are available in stainless steels. If required, threading is also provided on the outer body to suit positioning in threaded holes or fixtures. These Hydro speed regulators are available ex-stock at an affordable price.
        </p>
      </div>

      <div className="md:w-1/4 p-8 border border-gray-300 shadow-lg md:mr-6 mb-10">
        <h2 className="text-2xl font-bold mb-4">OUR PRODUCTS</h2>
        <ul className="list-disc">
          <li>Industrial Shock Absorbers</li>
          <hr className="border-gray-300 my-1" />
          <li>Linear Motion Slides</li>
          <hr className="border-gray-300 my-1" />
          <li>Quick Clamps</li>
          <hr className="border-gray-300 my-1" />
          <li>Pneumatic Rodless Cylinders</li>
          <hr className="border-gray-300 my-1" />
          <li>Vacuum Suction Cups</li>
          <hr className="border-gray-300 my-1" />
          <li>Wire Rope Isolaters</li>
          <hr className="border-gray-300 my-1" />
          <li>Crane Buffers</li>
          <hr className="border-gray-300 my-1" />
          <li>Cable Trays</li>
          <hr className="border-gray-300 my-1" />
          <li>Hydraulic Feed Rate Controllers</li>
          <hr className="border-gray-300 my-1" />
        </ul>

        <div className="mt-8">
          <h2 className="text-2xl font-bold mb-4">DOWNLOADS</h2>
          <ul className="list-disc">
            <li>Brochure, English version</li>
            <hr className="border-gray-300 my-1" />
            <li>Product catalogue (DE/EN)</li>
            <hr className="border-gray-300 my-1" />
            <li>Product catalogue (EN/CN)</li>
            <hr className="border-gray-300 my-1" />
            <li>Load tables</li>
            <hr className="border-gray-300 my-1" />
            <li>Traceparts</li>
            <hr className="border-gray-300 my-1" />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HydraulicFeedRateControllers;
