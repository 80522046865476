import React from "react";
import { Table } from "antd";
const TableData = (props) => {
    const {columns,data}=props;
 

  
  return <Table  dataSource={data} columns={columns} pagination={false} scroll={{x:100}} bordered/>;
};

export default TableData;
