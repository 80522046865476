import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Heading = styled.h2`
  color: #0177bf;
  font-weight: 600;
  text-align: center;
  margin-top: 40px;
  font-size: 2.1rem;

  @media (max-width: 768px) {
    font-size: 1.5rem;
    margin-top: 20px;
  }
`;

const Text = styled.div`
  text-align: center;
  color: #445864;
  font-weight: 600;
  padding: 20px;
  margin-top: 20px; // Add margin to create a gap between settings and text
  font-size: 1.2rem;
  border-top: 2px solid #0177bf;
  border-bottom: 2px solid #0177bf;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 1rem;
    margin-top: 10px;
  }
`;

const WhyChooseUs = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000, // Increase the speed for smoother transition
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out", // Use a smoother easing function
  };

  return (
    <div>
      <Heading>Why Choose Us?</Heading>
      <Slider {...settings}>
        <Text>
          * Globally Competitive Pricing: Get the best value for your investment
          *
        </Text>
        <Text>
          * Fast Delivery: We ensure timely delivery of materials to keep your
          operations running smoothly.*
        </Text>
        <Text>
          * Comprehensive Support: Benefit from application analysis, guidance,
          detailed drawings, and 3D models. *
        </Text>
        <Text>
          * Flexible Payment Options: Indian clients can enjoy the convenience
          of payments in INR. *
        </Text>
        <Text>
          * Manufacturer Direct: Work directly with us, the manufacturers, for
          reliable and consistent quality. *
        </Text>
        <Text>
          * Free Product Trials: Experience our products firsthand with no
          commitment. *
        </Text>
        <Text>
          * Servicing & Repairs: We offer servicing for imported shock absorbers
          and buffers. *
        </Text>
        <Text>
          * Customized Solutions: Tailored solutions to meet your specific
          needs. *
        </Text>
      </Slider>
    </div>
  );
};

export default WhyChooseUs;
