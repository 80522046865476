import React from "react";

const CraneTrays = () => {
  return (
    <div className=" md:ml-10 sm:ml-6 sm:mt-8 lg:ml-10 lg:mt-25">
      <div className="md:ml-4 mt-6 text-2xl font-bold sm:ml-10 sm:mt-8 sm:text-3xl">
        <h1 className="text-center md:text-left ">Crane Trays</h1>
        <hr className="my-3 w-full border-t-2 border-gray-400 sm:w-3/4 md:w-[95%]" />
      </div>
      <div className="md:mb-10 mt-6 flex flex-col items-center sm:mt-10 lg:ml-60 lg:flex-row lg:items-start">
        <img
          className="mb-4 md:ml-10 h-auto w-full sm:w-3/4 md:h-[300px] "
          src="/adoniNew/products/p24.jpg"
          alt=""
        />
      </div>
      <div className="ml-4 sm:ml-10">
        <p>
          We offer cable trays, electrical cable trays, GI cable trays,
          perforated cable trays, hot dip galvanized cable trays, galvanized
          cable trays, stainless steel cable trays, powder coated cable trays,
          trunking cable trays, cable raceways, ladder cable trays, cable
          raceways and cable tray accessories.
          <br />
        </p>
        <p>
          <br /> We are manufacturer and supplier of a wide range of Metal Cable
          Trays, which is extensively used across diverse industries.
          Manufactured under the supervision of experts, our products are made
          using high grade basic material such as stainless steel, mild steel &
          aluminum. The basic material has varied thickness from 1.6 mm to 6 mm.
          Our trays in diverse range of dimensions, which include the following
          <strong className="text-[14px]">
            <br />
            Height: 15 mm to 50 mm. <br />
            Width: 50 mm to 1250 mm <br />
          </strong>{" "}
          Further, clients can avail these in perforated & ladder type of cable
          trays.
        </p>
      </div>
     
          <div className="relative flex justify-center mt-5 ">
        <div className="text-center">
          <h1 className="tracking-wide text-2xl mb-4 bg-gray-100 text-gray-500 p-2 rounded-lg w-64 mx-auto">DETAIL BROCHURE</h1>
        </div>
      </div>
      <div className="md:flex justify-center md:items-center mt-5 ml-3 mr-3 ">
        <iframe
          title="PDF Viewer"
          className="border-black md:w-[800px] md:h-[1020px]  h-[455px] w-full  mb-10"
          src="https://drive.google.com/file/d/1Jw0lzR4FFU9UWtMNkfDgXtdyul7d8w7b/preview"
          frameBorder="0"
          scrolling="auto"
        />

      </div>
         
        </div>
     
  );
};

export default CraneTrays