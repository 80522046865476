import React from 'react';

const LinearMotions = () => {
  return (
    <div className="md:mt-28">
      <div className="text-3xl md:ml-10 text-center md:text-left font-bold mt-8">
        <h1>Linear Motion Slides</h1>
        <hr className="border-t-2 border-gray-200 my-3 md:w-[95%]" />
      </div>
      <div class="md:flex items-center md:items-start">
        <img
          class="mx-auto md:w-1/3 md:float-left md:m-10"
          src="/adoniNew/products/p16.jpg"
          alt=""
        />

        <div className="md:flex-1 p-4">
          <p className="text-justify float-left md:m-10 font-sans text-base leading-7  text-gray-700">
            adoniTech has launched a new solution to the linear motion technology the Linear Motion Slides.
            The featured product is a adoniTech Linear Motion Slide without drive arrangement.
            Variants as per the application are manufactured.
            The linear guidance system is profiled Linear Motion guide ways with Recirculating ball type LM blocks. The slides are driven by IAR precision ground ball screws. Various sizes are available and in various length.
            Better overall accuracies and smooth operation are a result of quality ball screw and precision manufacturing of the parts.
            As per the requirement of the application adoniTech offers these slides in Coarse, Normal and Precision positioning accuracy as well as suitable for Lights, medium or heavy loads.
            These slides can be used for automation, Transport, Machine tools, Material handling, positioning of work heads as well as work pieces.
          </p>
        </div>
      </div>
      <div>
        <img
          className="md:ml-50 md:w-[1000px]"
          src="/adoniNew/products/p17.jpg"
          alt=""
        />
      </div>
      <div>
        <img
          className="md:ml-50 md:w-[1000px] mb-10"
          src="/adoniNew/products/p18.jpg"
          alt=""
        />
      </div>
    </div>
  );
}

export default LinearMotions;
