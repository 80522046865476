import React from 'react';

const WireRopeIsolators = () => {
    return (
        <div className="md:mt-25 md:ml-10 mt-10">
            <div className="text-3xl md:ml-10 font-bold mt-8">
                <h1 className="text-center md:text-left">Wire Rope Isolator</h1>
                <hr className="border-t-2 border-gray-400 my-3 md:w-[90%]" />
            </div>
            <div className="md:flex items-center md:ml-20 md:items-start">
                <img
                    className="md:w-2/5 md:float-left"
                    src="/adoniNew/products/p22.jpg"
                    alt=""
                />
                <div className="w-full md:w-1/2 p-4">
                    <p className="text-black">
                        Originating from American design and technology, AdoniTech specializes
                        in research and development, manufacture, testing and solutions of
                        energy absorption and vibration control products like hydraulic shock
                        absorbers, viscous dampers, wirerope vibration isolators, polyurethane
                        cushions etc. R&D and manufacture are based in Wuxi, Jiangsu Province.
                        Currently, AdoniTech has 4 complete product lines and the leading
                        product testing lab in the industry.
                    </p>
                    <br />
                    <p className="tracking-wide text-[22px] text-gray-500 p-2 rounded-lg mx-auto">
                        ADONITECH'S PRODUCT'S DIFFERENCES AND FEATURES:
                    </p>
                    <ul className="list-disc ml-6">
                        <li>Leading product design in the world.</li>
                        <li>
                            All the key components use top brand products in the industry, more
                            than 60% components are originally from US.
                        </li>
                        <li>Standardized assembly process.</li>
                        <li>
                            High precision, testing of whole series equipments, testing rate
                            of finished products as high as 100%.
                        </li>
                    </ul>
                </div>
            </div>
            <p className="tracking-wider text-2xl text-gray-500 p-2 ml-10 rounded-lg mx-auto">
                ADONITECH'S BRAND ADVANTAGES:
            </p>
            <ul className="list-disc md:ml-25 ml-10">
                <li>Excellent working life and performance.</li>
                <li>Prompt product delivery, enough safety inventories.</li>
                <li>Quick and good technical supports such as product sizing, product testing, and solutions.</li>
                <li>On-site service support from domestic professionals within 24 hours.</li>
            </ul>
            <div className="relative md:flex md:justify-center mt-5 ">
                <div className="text-center">
                    <h1 className="tracking-wide text-2xl mb-4 bg-gray-100 text-gray-500 p-2 rounded-lg w-64 mx-auto">DETAIL BROCHURE</h1>
                    <div className="md:flex md:justify-center md:items-center mt-5 ml-3 mr-3">
                        <iframe
                            title="PDF Viewer"
                            className="border-black md:w-[800px] md:h-[1077px]  h-[480px] w-full  mb-10"
                            src="https://drive.google.com/file/d/1jnMiaLh-NeyvzW9JWMulXyjz-hVzaegE/preview"
                            frameBorder="0"
                            scrolling="auto"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WireRopeIsolators;
