import React from "react";
import BannerClouser from "../components/Sections/Home/banner/BannerClouser";
import About from "../components/Sections/Home/banner/About/About";
import Title from "../components/Sections/Home/banner/Title/Title";
import FirstScreen from "../components/Sections/Home/FirstScreen/FirstScreen";
import EquipmentRange from "../components/Sections/Home/EquipmentRange/EquipmentRange";
import Header from "../components/Sections/Header";
import Challenges from "../components/Sections/Challenges";
import Projects from "../components/Sections/Projects";
import CorporateServices from "../components/Sections/Home/cards/CorporateServices";
import WhyChooseUs from "../components/Sections/WhyChooseUs";
import TestimonialSlider from "../components/Elements/TestimonialSlider";
import Testimonial from "../components/Sections/Testmonial";
import banner from "../assets/img/logo/banner.jpg";
import TopNavbar from "../components/Nav/TopNavbar";

export default function Landing() {
  return (
    <>
      {/* <div
        style={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingBottom: "40px", // Space below the image
        }}
      >
        <TopNavbar />
        <BannerClouser />
        <About style={{ marginTop: "20px" }} />
      </div>






      <Testimonial /> */}
      <BannerClouser />
      <About />
      <Title />
      <FirstScreen />
      <EquipmentRange />
      <Header />
      <Challenges />
      <Projects />
      <CorporateServices />
      <WhyChooseUs />
      <TestimonialSlider />
    </>
  );
}
