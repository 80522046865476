import React, { useEffect, useState } from 'react';
import Table from './Download3dTable';
import axios from 'axios';


const Main3d = () => {
    const [Category, setCategory] = useState('All');


    const [data, setData] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://3d.adonitech.co.in/modals/get_modal');
                console.log(response.data)
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div className=" md:mx-20 md:mt-20 md:flex">
            <div className="md:w-1/4   p-4">
                <h2 className="text-lg font-bold">Download</h2>
                <ul className="sm:mt-4 flex md:flex-col w-1/6">
                    <li>
                        <button
                            onClick={() => setCategory('All')}
                            className={`block w-full text-left p-2 ${Category === 'All' ? 'bg-indigo-500 text-white' : 'text-indigo-500'}`}
                        >
                            All
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => setCategory('Catalog')}
                            className={`block w-full text-left p-2 ${Category === 'Catalog' ? 'bg-indigo-500 text-white' : 'text-indigo-500'}`}
                        >
                            Catalog
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => setCategory('Drawing')}
                            className={`block w-full text-left p-2 ${Category === 'Drawing' ? 'bg-indigo-500 text-white' : 'text-indigo-500'}`}
                        >
                            Drawing
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => setCategory('3D')}
                            className={`block w-full text-left p-2 ${Category === '3D' ? 'bg-indigo-500 text-white' : 'text-indigo-500'}`}
                        >
                            3D
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => setCategory('CAD')}
                            className={`block w-full text-left p-2 ${Category === 'CAD' ? 'bg-indigo-500 text-white' : 'text-indigo-500'}`}
                        >
                            CAD
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => setCategory('PDF')}
                            className={`block w-full text-left p-2 ${Category === 'PDF' ? 'bg-indigo-500 text-white' : 'text-indigo-500'}`}
                        >
                            PDF
                        </button>
                    </li>
                </ul>
            </div>
            <div className="w-3/4 p-4">
                <Table data={data} entriesPerPage={8} Category={Category} />
            </div>
        </div>
    );
};
export default Main3d;
