import React, { useState, useEffect } from "react";

const Table = ({ data, entriesPerPage, Category }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      let filtered = data;
      if (Category !== "All") {
        filtered = filtered.filter((item) => item.Category === Category);
      }
      if (searchTerm) {
        filtered = filtered.filter(
          (item) =>
            item.ModelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.ProductName.toLowerCase().includes(searchTerm.toLowerCase()),
        );
      }
      setFilteredData(filtered);
      setLoading(false);
    }, 500);
  }, [searchTerm, Category, data]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);
  const currentData = filteredData.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage,
  );

  return (
    <div className="p-4">
      <input
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleSearch}
        className="mb-4 rounded border border-gray-300 p-2"
      />
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr className="bg-indigo-200">
            <th>ID</th>
            <th>Model</th>
            <th>Product</th>
            <th>Actions</th>
          </tr>
        </thead>
        {loading ? (
          "Loading...."
        ) : (
          <tbody className="divide-y divide-gray-200 bg-white text-center">
            {currentData.map((item, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{item.ModelName}</td>
                <td>{item.ProductName}</td>
                <td className="flex justify-center">
                  {/* Preview Icon */}
                  <a
                    href={item.productFile}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-2 text-indigo-500"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 4.5c-7.333 0-10 5.833-10 7.5s2.667 7.5 10 7.5 10-5.833 10-7.5-2.667-7.5-10-7.5zm0 13c-3.864 0-7.25-3.31-7.25-5.5s3.386-5.5 7.25-5.5c3.865 0 7.25 3.31 7.25 5.5s-3.385 5.5-7.25 5.5zm0-9c-1.659 0-3 1.341-3 3s1.341 3 3 3 3-1.341 3-3-1.341-3-3-3zm0 4c-.551 0-1-.449-1-1s.449-1 1-1 1 .449 1 1-.449 1-1 1z" />
                    </svg>
                  </a>
                  {/* Download Icon */}
                  <a
                    href={item.productFile}
                    download={`${item.ProductName}.pdf`}
                    className="text-indigo-500"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 20h14v-2H5v2zm7-18l-5.5 5.5h4v7h3v-7h4L12 2z" />
                    </svg>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <div className="mt-4 flex items-center justify-between">
        <div>
          Showing {currentData.length} of {filteredData.length} entries
        </div>
        <div>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              className={`mx-1 rounded border px-3 py-1 ${
                i + 1 === currentPage
                  ? "bg-indigo-500 text-white"
                  : "bg-white text-indigo-500"
              }`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Table;
