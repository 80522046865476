import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style/flexboxgrid.min.css";
import "./style/index.css";
import App from "./App";

// import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById("root"),
);
