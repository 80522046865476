import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./firstScreen.css";
import { Link } from "react-router-dom";

const images = [
  {
    id: 1,
    src: "/adoniNew/home/curve-brochure (1).png",
    title1: "   Heavy Duty ",
    title2: "Shock Absorber",
  },

  {
    id: 2,
    src: "/adoniNew/home/slide3.png",
    title1: "   Miniature",
    title2: "Shock Absorber",
  },
];

const FirstScreen = () => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 1500,
  };

  return (
    <div className="w-full">
      <Slider {...settings}>
        {images.map((image) => (
          <div key={image.id}>
            <section
              className="px-3 py-5 lg:py-10"
              style={{
                background:
                  "linear-gradient(to right, rgb(176, 186, 195) 50%, #ffffff 100%)",
              }}
            >
              <div className="grid items-center justify-items-center gap-5 lg:grid-cols-2">
                <div className="order-2 flex flex-col items-center justify-center lg:order-1">
                  <p className="text-2xl font-bold text-[#2f3c44] sm:text-4xl md:text-5xl lg:text-7xl">
                    {image.title1}
                  </p>
                  <p className="text-2xl font-bold text-[#2f3c44] sm:text-4xl md:text-5xl lg:text-7xl">
                    {image.title2}
                  </p>
                  {/* <Link to="/contact-us">
                    <button className="mt-10 bg-black px-5 py-2 text-lg text-white hover:bg-zinc-800 md:text-2xl">
                      Contact Now
                    </button>
                  </Link> */}
                </div>
                <div className="order-1 lg:order-2">
                  <img
                    className="h-[150px] w-40 sm:h-[200px] sm:w-60 md:h-[300px] md:w-80 lg:h-[400px] lg:w-[400px]"
                    src={image.src}
                    alt=""
                  />
                </div>
              </div>
            </section>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FirstScreen;
