import React from "react";
import styled from "styled-components";
import image3 from "../../../../assets/img/logo/image.jpg";
import image4 from "../../../../assets/img/equipments/img3.jpg";

const EquipmentRange = () => {
  return (
    <Section>
      <TextWrapper>
        <h1>
          Our range of Industrial <br /> Shock absorbers and <br /> vibration
          isolation <br /> equipment's
        </h1>
      </TextWrapper>
      <CardsWrapper>
        <Card>
          <ImageContainer>
            <img src={image4} alt="Pneumatic Rodless Cylinders" />
          </ImageContainer>
          <CardTitle>Hydraulic/Crane Buffers</CardTitle>
          <CardDescription>
            Reliable hydraulic buffers <br /> for crane applications..
          </CardDescription>
        </Card>
        <Card>
          <ImageContainer>
            <img
              src={image3}
              className="mt-5"
              alt="Industrial Shock Absorbers"
            />
          </ImageContainer>
          <CardTitle>Industrial Shock Absorbers</CardTitle>
          <CardDescription>
            High-performance shock absorbers <br />
            for industrial applications.
          </CardDescription>
        </Card>
      </CardsWrapper>
    </Section>
  );
};

// Styled components
const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  text-align: center;
  margin-bottom: 20px;

  h1 {
    font-size: 1.5rem;
    font-weight: 650;
    color: #1a73e8;
    display: inline-block;
    padding: 8px 12px;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
`;

const CardsWrapper = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 50px;
    justify-content: flex-start;
    margin-right: 30px;
    max-width: 800px;
  }
`;

const Card = styled.div`
  flex: 1;
  padding: 2px;
  border: 2px solid #0177bf;
  text-align: center;
  width: 100%;
  height: 300px; /* Set a fixed height for all cards */
  max-width: 300px; /* Set a fixed width for all cards */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 768px) {
    width: auto;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;

  img {
    width: 70%;
    height: auto;
  }
`;

const CardTitle = styled.h2`
  font-size: 1rem;
  font-weight: 650;
  color: #333;
  margin-bottom: 8px;

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const CardDescription = styled.p`
  font-size: 0.8rem;
  color: #666;
  flex-grow: 1; /* Ensure the description takes up available space */

  @media (min-width: 768px) {
    font-size: 0.9rem;
  }
`;

export default EquipmentRange;
