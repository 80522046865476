import React from 'react';
import { FaSquareArrowUpRight } from "react-icons/fa6";

const PneumaticRodlessCylinders = () => {
  return (
    <div className="md:mt-30 mt-10 ml-3 mr-3 ">
      <h1 className="text-3xl  font-semibold text-center md:text-left md:ml-10">Pneumatic Rodless Cylinders</h1>
      <hr className="border-t-2 md:ml-10 border-gray-200 my-3 md:w-[92%]" />
      <table className="border border-black border-collapse md:ml-10  mt-10 md:w-[1200px]">

        <thead>
          <tr>
            <th className="border border-black px-4 py-2 font-bold">Please choose the desired product</th>
            <th className="border border-black px-4 py-2 font-bold">Standard</th>
            <th className="border border-black px-4 py-2 font-bold">Short</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-black px-4 py-2">Standard cylinder</td>
            <td className="border border-black px-4 py-2">STANDARD CYLINDER (ZS/ZK)</td>
            <td className="border border-black px-4 py-2">STANDARD CYLINDER (ZS/ZK)</td>
          </tr>
          <tr>
            <td className="border border-black px-4 py-2">Standard cylinder with broad yoke head</td>
            <td className="border border-black px-4 py-2">STANDARD CYLINDER WITH BROAD YOKE HEAD (ZS+/ZK+)</td>
            <td className="border border-black px-4 py-2">STANDARD CYLINDER WITH BROAD YOKE HEAD (ZS+/ZK+)</td>
          </tr>
          <tr>
            <td className="border border-black px-4 py-2">Guiding Cylinder with external carriage</td>
            <td className="border border-black px-4 py-2">GUIDING CYLINDER WITH EXTERNAL CARRIAGE (ZF/ZFK, ZFU UND ZFF)</td>
            <td className="border border-black px-4 py-2">GUIDING CYLINDER WITH EXTERNAL CARRIAGE (ZF/ZFK, ZFU UND ZFF)</td>
          </tr>
          <tr>
            <td className="border border-black px-4 py-2">Guiding Cylinder with external ball bearing guide</td>
            <td className="border border-black px-4 py-2">GUIDING CYLINDER WITH EXTERNAL BALL BEARING GUIDE (ZSS/ZKS)</td>
            <td className="border border-black px-4 py-2">GUIDING CYLINDER WITH EXTERNAL BALL BEARING GUIDE (ZSS/ZKS)</td>
          </tr>
          <tr>
            <td className="border border-black px-4 py-2">Parallel cylinder</td>
            <td className="border border-black px-4 py-2">PARALLEL CYLINDER (ZP)</td>
            <td className="border border-black px-4 py-2">PARALLEL CYLINDER (ZP)</td>
          </tr>
          <tr>
            <td className="border border-black px-4 py-2">Guiding Cylinder with passive safety unit</td>
            <td className="border border-black px-4 py-2">GUIDING CYLINDER WITH PASSIVE LOCKING UNIT (ZFB)</td>
            <td className="border border-black px-4 py-2"></td>
          </tr>
          <tr>
            <td className="border border-black px-4 py-2">Tandem cylinder</td>
            <td className="border border-black px-4 py-2">TANDEM CYLINDER (ZT)</td>
            <td className="border border-black px-4 py-2">TANDEM CYLINDER (ZT)</td>
          </tr>
          <tr>
            <td className="border border-black px-4 py-2">Gripping cylinder</td>
            <td className="border border-black px-4 py-2">GRIPPING CYLINDER (ZG)</td>
            <td className="border border-black px-4 py-2">STANDARD CYLINDER (ZS/ZK)</td>
          </tr>
        </tbody>
      </table>
      <div className="relative flex justify-center mt-5">
        <div className="text-center">
          <h1 className="tracking-wide text-2xl mb-4 bg-gray-100 text-gray-500 p-2 rounded-lg w-64 mx-auto">DETAIL BROCHURE</h1>
        </div></div>
      <div class="relative flex justify-center mt-5 mb-10">
        <iframe
          title="PDF Viewer"
          class="border-black md:w-[800px] md:h-[1100px]  h-[514px] w-full"
          src="https://drive.google.com/file/d/1eicroCMAoUMfz3Och0VnhyP9z8XTKqDK/preview"
         
          frameBorder="0"
          scrolling="auto"
        />

      </div>
    </div>
  );
}

export default PneumaticRodlessCylinders;
