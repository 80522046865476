import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./bannerClouser.css";
import { Link } from "react-router-dom";

const BannerClouser = () => {
  return (
    <div className="mb-14 mt-[100px] w-full sm:mt-[200px] md:mt-30">
      <h1 className="banner-text flex justify-center pt-10 text-xl font-bold text-[#0478BF] sm:text-2xl md:text-4xl">
        Welcome to Adonitech
      </h1>
      <h2 className="banner-text flex justify-center p-2 text-lg font-bold text-[#0478BF] sm:text-xl md:text-3xl">
        Protecting Your Assets, Enhancing Your Efficiency
      </h2>
    </div>
  );
};

export default BannerClouser;
