import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import icon3 from "../../assets/img/logo/icon3.png";
import icon2 from "../../assets/img/logo/icon2.png";
import icon1 from "../../assets/img/logo/icon1.png";
import icon4 from "../../assets/img/logo/service.png";
import icon6 from "../../assets/img/logo/icon6.png";
import icon7 from "../../assets/img/logo/sd.png";
import icon8 from "../../assets/img/logo/customize.png";

// Challenges array
const challenges = [
  {
    title: "High Costs",
    description: "We offer competitive pricing to reduce your expenses.",
    icon: icon2,
  },
  {
    title: "Long Sourcing Durations",
    description: "Our efficient processes ensure quick material sourcing.",
    icon: icon3,
  },
  {
    title: "Lack of Drawings and Analysis",
    description: "We provide detailed application analysis and drawings.",
    icon: icon1,
  },
  {
    title: "Absence of 3D Models",
    description: "Access comprehensive 3D models for better planning.",
    icon: icon7,
  },
  {
    title: "No Servicing Facilities",
    description: "We provide full servicing and repair facilities.",
    icon: icon4,
  },
  {
    title: "Rigid Customization Options",
    description: "We offer flexible and customized shock absorber solutions.",
    icon: icon8,
  },
  {
    title: "USD Payments and Dealer Markups",
    description:
      "Enjoy direct payments in INR and avoid high dealer markups for Indian clients.",
    icon: icon6,
  },
];

// Main component
const Challenges = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Section>
      <Title>Industry Challenges We Address</Title>
      <SliderContainer>
        {isMobile ? (
          <MobileContainer>
            {challenges.map((challenge, index) => (
              <Card key={index}>
                <IconPlaceholder>
                  <img src={challenge.icon} alt="icons" />
                </IconPlaceholder>
                <CardTitle>{challenge.title}</CardTitle>
                <CardDescription>{challenge.description}</CardDescription>
              </Card>
            ))}
          </MobileContainer>
        ) : (
          <Slider {...settings}>
            {challenges.map((challenge, index) => (
              <Card key={index}>
                <IconPlaceholder>
                  <img src={challenge.icon} alt="icons" />
                </IconPlaceholder>
                <CardTitle>{challenge.title}</CardTitle>
                <CardDescription>{challenge.description}</CardDescription>
              </Card>
            ))}
          </Slider>
        )}
      </SliderContainer>
    </Section>
  );
};

// Styled Components
const Section = styled.section`
  padding: 50px 100px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #1a73e8;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SliderContainer = styled.div`
  .slick-slide {
    display: flex;
    justify-content: center;
    padding: 15px;
  }

  .slick-dots li button:before {
    color: #1a73e8;
  }

  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #1a73e8;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = styled.div`
  border: 2px solid #0177bf;
  padding: 30px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 350px;
  transition:
    transform 0.3s,
    box-shadow 0.3s;
  margin-bottom: 20px;

  &:hover {
    transform: translateY(-10px);
    border-color: #1a73e8;
    box-shadow: 0 6px 16px rgba(26, 115, 232, 0.3);
  }

  @media (max-width: 768px) {
    padding: 20px;
    max-width: 90%;
    height: 400px; /* Ensure the same height for mobile view */
  }
`;

const IconPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  color: #1a73e8;
  margin-bottom: 20px;
`;

const CardTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CardDescription = styled.p`
  font-size: 0.9rem;
  color: #666;
  flex-grow: 1; /* Ensure the description takes up available space */

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export default Challenges;
