import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import logos (replace these with your actual paths)
import logo1 from "../../../../../assets/img/logo/logo1.png";
import logo2 from "../../../../../assets/img/logo/logo2.png";
import logo3 from "../../../../../assets/img/logo/logo3.png";
import logo4 from "../../../../../assets/img/logo/logo4.png";
import logo5 from "../../../../../assets/img/logo/logo5.png";
import logo6 from "../../../../../assets/img/logo/logo6.png";

const Title = () => {
  const handleButtonClick = () => {
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSenjfVQkT7xD3rb33SPNk4uQgqfejgCzPPEGywYcYtE_Hs2qA/viewform?embedded=true",
      "_blank",
    );
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    swipe: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const clients = [
    { name: "Client 1", logo: logo1 },
    { name: "Client 2", logo: logo2 },
    { name: "Client 3", logo: logo3 },
    { name: "Client 4", logo: logo4 },
    { name: "Client 5", logo: logo5 },
    { name: "Client 6", logo: logo6 },
  ];

  return (
    <SectionWrapper>
      {/* Header and buttons */}
      <Header>
        <TextContainer>
          <h2>
            We are committed to safeguarding, your <br /> valuable assets from
            impact and vibrations.
          </h2>
        </TextContainer>
        <ButtonGroup>
          <ActionButton onClick={handleButtonClick}>Free Trial</ActionButton>
          <ActionButton onClick={handleButtonClick}>Request Quote</ActionButton>
        </ButtonGroup>
      </Header>

      {/* Slider */}
      <SliderWrapper>
        <Slider {...settings}>
          {clients.map((client, index) => (
            <LogoWrapper key={index}>
              <img src={client.logo} alt={client.name} className="logo-image" />
            </LogoWrapper>
          ))}
        </Slider>
      </SliderWrapper>
    </SectionWrapper>
  );
};

// Styled components
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  background: #f8f9fa;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TextContainer = styled.div`
  text-align: center;

  h2 {
    font-size: 24px;
    font-weight: 600;
    color: #0177bf;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      text-align: left;
      font-size: 38px;
    }
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between buttons */

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px; /* Space between buttons */
  }
`;

const ActionButton = styled.button`
  border: 2px solid #445864;
  color: #2f3c44;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease-in;
  margin-bottom: 10px;

  &:hover {
    background-color: #2f3c44;
    color: white;
  }

  @media (min-width: 768px) {
    padding: 14px 50px;
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .logo-image {
    width: 150px;
    height: 120px;
    object-fit: contain;
    background: transparent;

    @media (min-width: 768px) {
      width: 200px;
      height: 160px;
    }
  }
`;

export default Title;
