import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

const ProductDropdown = () => {
  return (
    <div className="max-lg:hidden flex  justify-center px-3 text-[16px] font-semibold  hover:text-blue-400 ">
      <FlyoutLink to="#" FlyoutContent={PricingContent}>
        <label className="cursor-pointer text-black">Products</label>
      </FlyoutLink>
    </div>
  );
};

const FlyoutLink = ({ children, to, FlyoutContent }) => {
  const [open, setOpen] = useState(false);

  const showFlyout = FlyoutContent && open;

  return (
    <div
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      className="relative h-fit w-fit"
    >
      <Link to={to} className="relative text-stone-500">
        {children}
        <span
          style={{
            transform: showFlyout ? "scaleX(1)" : "scaleX(0)",
          }}
          className="absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-indigo-300 transition-transform duration-300 ease-out"
        />
      </Link>
      <AnimatePresence>
        {showFlyout && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            style={{ translateX: "-50%" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            className="absolute left-1/2 top-12 bg-white text-black"
          >
            <div className="absolute -top-6 left-0 right-0 h-6 bg-transparent" />
            <div className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white" />
            <FlyoutContent />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const PricingContent = () => {
  const navigate = useNavigate();

  const handleNavigation = (category, e) => {
    e.preventDefault();
    console.log(category);
    navigate(`/product/${category}`);
  };

  return (
    <div className="w-64 bg-white p-6 shadow-xl">
      <div className="mb-3 space-y-3">
        {/* <h3 className="font-semibold">For Individuals</h3> */}
        <p
          onClick={(e) => handleNavigation("industrialShockAbsorber", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Industrial Shock Absorbers
        </p>
        <p
          onClick={(e) => handleNavigation("craneBuffers", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Crane Buffers
        </p>
        <p
          onClick={(e) => handleNavigation("wireRopeIsolators", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Wire Rope Isolators
        </p>
        <p
          onClick={(e) => handleNavigation("hydraulicFeedRateControllers", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Hydraulic Feed Rate Controllers
        </p>
        <p
          onClick={(e) => handleNavigation("rotaryShockAbsorber", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Rotary Shock Absorbers
        </p>
        <p
          onClick={(e) => handleNavigation("shockAbsorberForSwitchgear", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Shock Absorber for Switchgear
        </p>
        <p
          onClick={(e) => handleNavigation("rodlessCylinder", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Rodless Cylinder
        </p>

        <p
          onClick={(e) => handleNavigation("linearMotionSlides", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Linear Motion Slides
        </p>
        <p
          onClick={(e) => handleNavigation("quickClamps", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Quick Clamps
        </p>
        <p
          onClick={(e) => handleNavigation("pneumaticRodlessCylinders", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Pneumatic Rodless Cylinders
        </p>
        <p
          onClick={(e) => handleNavigation("vaccumSuctionCups", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Vaccum Suction Cups
        </p>

        <p
          onClick={(e) => handleNavigation("cableTrays", e)}
          className="block cursor-pointer text-sm hover:underline"
        >
          Cable Trays
        </p>
      </div>
      {/* <div className="mb-6 space-y-3">
                <h3 className="font-semibold">For Companies</h3>
                <a href="#" className="block text-sm hover:underline">
                    Startups
                </a>
                <a href="#" className="block text-sm hover:underline">
                    SMBs
                </a>
                <a href="#" className="block text-sm hover:underline">
                    Enterprise
                </a>
            </div> */}
      {/* <button className="w-full rounded-lg border-2 border-neutral-950 px-4 py-2 font-semibold transition-colors hover:bg-neutral-950 hover:text-white">
                Contact sales
            </button> */}
    </div>
  );
};

export default ProductDropdown;
