import React, { Children } from "react";
import TableData from "./table/Table";
import { FaSquareArrowUpRight } from "react-icons/fa6";

const LargeSeries = () => {
  const DIMENSIONcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Thread",
      dataIndex: "thread",
      key: "thread",
    },
    {
      title: "A",
      dataIndex: "a",
      key: "a",
    },
    {
      title: "B",
      dataIndex: "b",
      key: "b",
    },
    {
      title: "C",
      dataIndex: "c",
      key: "c",
    },
    {
      title: "D",
      dataIndex: "d",
      key: "d",
    },
    {
      title: "E",
      dataIndex: "e",
      key: "e",
    },
    {
      title: "F",
      dataIndex: "f",
      key: "f",
    },
    {
      title: "G",
      dataIndex: "g",
      key: "g",
    },
    {
      title: "H",
      dataIndex: "h",
      key: "h",
    },
  ];
  const DIMENSIONdata = [
    {
      key: "1",
      model: "AD 30-25 M",
      thread: "M 30X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "2",
      model: "AD 33-25 M",
      thread: "M 33X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "3",
      model: "AD 33-25 U",
      thread: "1 1/4-12U",
      a: "6.2",
      b: "3.7",
      c: "0.5",
      d: "0.4",
      e: "1.1",
      f: "0.3",
      g: "1.0",
      h: "0.3",
    },
    {
      key: "4",
      model: "AD 36-25 M",
      thread: "M 36X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "5",
      model: "AD 37-25 M",
      thread: "M 37X1.5",
      a: "157",
      b: "95",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "25",
      h: "7",
    },
    {
      key: "6",
      model: "AD 30-50 M",
      thread: "M 30X1.5",
      a: "207",
      b: "120",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "50",
      h: "7",
    },
    {
      key: "7",
      model: "AD 33-50 M",
      thread: "M 33X1.5",
      a: "207",
      b: "120",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "50",
      h: "7",
    },
    {
      key: "8",
      model: "AD 36-50 M",
      thread: "M 36X1.5",
      a: "207",
      b: "120",
      c: "12",
      d: "10",
      e: "28",
      f: "8",
      g: "50",
      h: "7", 
    },
    {
      key:"9",
      model:"AD 37-50 M",
      thread:"M 37X1.5",
      a:"207",
      b:"120",
      c:"12",
      d:"10",
      e:"28",
      f:"8",
      g:"50",
      h:"7"  
    },
    {
      key:"10",
      model:"AD 37-75 M",
      thread:"M 37X1.5",
      a:"262",
      b:"145",
      c:"12",
      d:"10",
      e:"28",
      f:"8",
      g:"75",
      h:"7"
    }
  ];
  const PERFORMANCEcolumn = [
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
    },
    {
      title: "Stroke",
      dataIndex: "stroke",
      key: "stroke",
    },
    {
      title: "Energy",
      children: [
        {
          title: "Nm/Stroke",
          dataIndex: "energy",
          key: "energy",
        },
      ],
    },
    {
      title: "Absorbation",
      children: [
        {
          title: "Nm/h",
          dataIndex: "absorbation",
          key: "absorbation",
        },
      ],
    },
    {
      title: "Effective Mass(KG)",
      children: [
        
        {
          title: "Min. Kg.",
          dataIndex: "minkg",
          key: "minkg",
        },
        {
          title: "Max. Kg.",
          dataIndex: "maxkg",
          key: "maxkg",
        }
      ],
    },
    {
      title:"Spring Force",
      children:[
        {
          title:"Min. N",
          dataIndex:"minN",
          key:"minN"
        },
        {
          title:"Max. N",
          dataIndex:"maxN",
          key:"maxN"
        }
      ]
    },
    {
      title:"Wt. GM.",
      dataIndex:"wt",
      key:"wt"
    }
  ];

  const PERFORMANCEdata = [
   {
      key: "1",
      model:"AD 42-25 MU",
      stroke:"25",
      energy:"250",
      absorbation:"132,000",
      minkg:"35-3500",
      maxkg:"3000-10000",
      minN:"60",
      maxN:"90",
      wt:"1200"
   },
   {
      key: "2",
      model:"AD 42-50 MU",
      stroke:"50",
      energy:"500",
      absorbation:"150,000",
      minkg:"50-1650",
      maxkg:"4800-17000",
      minN:"60",
      maxN:"120",
      wt:"1500"
   },
   {
      key: "3",
      model:"AD 42-75 MU",
      stroke:"75",
      energy:"1,200",
      absorbation:"190,000",
      minkg:"60-10000",
      maxkg:"8500-200000",
      minN:"50",
      maxN:"140",
      wt:"1900"

   },
   {
      key: "4",
      model:"AD 64-50 MU",
      stroke:"50",
      energy:"1,200",
      absorbation:"174,000",
      minkg:"70-12000",
      maxkg:"10000-450000",
      minN:"60",
      maxN:"130",
      wt:"3500"
   },
   {
      key: "5",
      model:"AD 64-100 MU",
      stroke:"100",
      energy:"2,300",
      absorbation:"235,000",
      minkg:"150-15000",
      maxkg:"12500-450000",
      minN:"60",
      maxN:"180",
      wt:"4500"
   },
   {
      key: "6",
      model:"AD 85-125 M",
      stroke:"125",
      energy:"5,500",
      absorbation:"935,000",
      minkg:"300-44000",
      maxkg:"42000-50000",
      minN:"90",
      maxN:"330",
      wt:"10800"
   },
   {
      key: "7",
      model:"AD 85-150 M",
      stroke:"150",
      energy:"6,000",
      absorbation:"1,500,000",
      minkg:"320-48000",
      maxkg:"45000-500000",
      minN:"90",
      maxN:"330",
      wt:"10470"
   },
   {
      key: "8",
      model:"AD 115-150 M",
      stroke:"150",
      energy:"11,000",
      absorbation:"	2,090,000",
      minkg:"355-88000",
      maxkg:"-",
      minN:"150",
      maxN:"320",
      wt:"20000"
   },
   {
      key: "9",
      model:"AD 115-200 M",
      stroke:"200",
      energy:"14,500",
      absorbation:"2,320,000",
      minkg:"390-116000",
      maxkg:"-",
      minN:"170",
      maxN:"380",
      wt:"23500"
   },
   {
      key: "10",
      model:"AD 115-250 M",
      stroke:"250",
      energy:"18,500",
      absorbation:"2,683,000",
      minkg:"450-14800C",
      maxkg:"-",
      minN:"120",
      maxN:"380",
      wt:"28000"
   }
  ];
  return (
    <>
      <h2 className="text-center text-4xl font-bold text-slate-600 ">
        Industrial Shock Absorbers
      </h2>
      <hr className="mt-6 border" />
      <div className="mt-10 md:flex md:items-center md:justify-around">
        <img src="/adoniNew/products/largeseries1.jpg" />
        <div>
          <ul style={{ listStyleType: "disc" }} className="text-xl">
            <li>Two locknuts inclusive</li>
            <li>Hydraulic Hardness fixed type</li>
            <li>Spring Return</li>
            <li>Ureathane striker Cap</li>
            <li>Special Anticorrosive Finish</li>
            <li>Hardened and Polished Rod</li>
            <li>special Viton Seals</li>
            <li>Nitrided wear parts for higher life</li>
            <li>Stainless steel model available</li>
            <li>Special versions available on request</li>
          </ul>
        </div>
      </div>
      <hr className="my-4 border" />
      <h2 className="my-8 text-center text-2xl">DIMENSIONS IN MILLIMETERS</h2>
      <TableData columns={DIMENSIONcolumn} data={DIMENSIONdata} />
      <h2 className="my-8 text-center text-2xl">PERFORMANCE TABLE</h2>
      <TableData columns={PERFORMANCEcolumn} data={PERFORMANCEdata} />
      <h2 className="my-8 text-center text-2xl">DETAIL BROCHURE</h2>
      <div className="relative flex justify-center">
        <img
          className="border-8 border-black"
          src="/adoniNew/home/series/largeseries-adonitech.png"
        />
        <a
          href="https://drive.google.com/file/d/1eet-e0S7be3E9sEHbYWSumld56idH_Zj/view"
          className="absolute left-[80%] top-5 text-2xl duration-300 hover:scale-150 hover:text-black "
        >
          <FaSquareArrowUpRight className=" bg-slate-500" />
        </a>
      </div>
    </>
  );
};

export default LargeSeries;
