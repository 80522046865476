import React from "react";
import styled from "styled-components";
import image2 from "../../assets/img/logo/image2.jpg";

// List of products with image and title
const products = [
  {
    title: "Industrial Shock Absorbers",
    image: image2,
  },
  {
    title: "Linear Motion Slides",
    image: "adoniNew/home/product/p2.jpg",
  },
  {
    title: "Quick Clamps",
    image: "adoniNew/home/product/p3.jpg",
  },
  {
    title: "Pneumatic Rodless Cylinders",
    image: "adoniNew/home/product/p4.jpg",
  },
  {
    title: "Vacuum Suction Cups",
    image: "adoniNew/home/product/p5.jpg",
  },
  {
    title: "Wire Rope Isolators",
    image: "adoniNew/home/product/p6.jpg",
  },
];

const ProductGrid = () => {
  return (
    <Section>
      <Title>Our Best Products</Title>
      <GridContainer>
        {products.map((product, index) => (
          <ProductCard key={index}>
            <ImageContainer>
              <ProductImage src={product.image} alt={product.title} />
            </ImageContainer>
            <ProductName>{product.title}</ProductName>
          </ProductCard>
        ))}
      </GridContainer>
    </Section>
  );
};

// Styled components for the layout and design
const Section = styled.section`
  padding: 50px 100px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  color: #1a73e8;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ProductCard = styled.div`
  background: #0066cc;
  padding: 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition:
    transform 0.3s,
    box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 16px rgba(0, 102, 204, 0.3);
  }

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const ImageContainer = styled.div`
  width: 350px;
  height: 220px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ProductImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const ProductName = styled.h3`
  font-size: 1rem;
  margin-top: 10px;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export default ProductGrid;
