import React from 'react'
import AddImage2 from "../../../../../../../assets/screen/screes1.jpg";

function Eiseries() {

    return (
        <div>
            <img className=' item-center flex justify-center mx-auto' src={AddImage2}></img>
        </div>
    )
}

export default Eiseries
