import React from "react";
import styled from "styled-components";
import image1 from "../../../../assets/img/logo/image2.jpg";
import image6 from "../../../../assets/img/logo/image6.png";

// Styled Components
const QualityPolicyContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #007bff;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Description = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 30px;
  color: #333;
  margin: 0 auto; /* Center the description */
  max-width: 800px; /* Optional: limit the width for better readability */

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap; /* Allow items to wrap to the next line on smaller screens */

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

const ItemCard = styled.div`
  width: 300px;
  height: 250px;
  padding: 10px;
  border: 25px solid #007bff;
  text-align: center;
  background-color: #f4f4f4;
  margin-top: 20px;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    padding: 5px;
    border: 15px solid #007bff;
  }
`;

const ItemImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: contain;
  border-bottom: 2px solid #007bff;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    height: auto;
  }
`;

const ItemTitle = styled.h3`
  margin-top: 10px;
  font-size: 1.2rem;
  color: #007bff;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const QualityPolicy = () => {
  const items = [
    {
      title: "Industrial Shock Absorber",
      image: image1, // Replace with actual image URL
    },
    {
      title: "Linear Motion Slider",
      image: image6, // Replace with actual image URL
    },
  ];

  return (
    <QualityPolicyContainer>
      <Title>Quality Policy</Title>
      <Description>
        Manufacturing, Trading and supply of Spares for transformer explosion
        prevention & Fire Extinguishing Systems, Hydraulic Shock Absorber’s for
        Aircraft Arrestor barrier systems, Industrial Shock Absorber for
        Automation, Plant & Machinery, Spares for Power Generation, Fire
        retardant Systems, Distribution & Protection Systems at good quality
        imbibing the Continual quality improvement programme and to conforming
        to the requirement of our customers and to strive for continuous
        improvement.
      </Description>
      <ItemsContainer>
        {items.map((item, index) => (
          <ItemCard key={index}>
            <ItemImage src={item.image} alt={item.title} />
            <ItemTitle>{item.title}</ItemTitle>
          </ItemCard>
        ))}
      </ItemsContainer>
    </QualityPolicyContainer>
  );
};

export default QualityPolicy;
