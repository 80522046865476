import { useState } from "react";
import { Layout, Menu, Dropdown, Button } from "antd";
import MiniSeries from "./component/MiniSeries";
import SmallSeries from "./component/SmallSeries";
import MediumSeries from "./component/MediumSeries";
import LargeSeries from "./component/LargeSeries";
import EDseries from "./component/EDseries";
import SBseries from "./component/SBseries";
import Eiseries from "./component/Eiseries";
import AKHG from "./component/AKHG";

const { Sider, Content } = Layout;

const Industrial = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("Mini Series");

  const seriesMenu = (
    <Menu onClick={({ key }) => setSelectedMenuItem(key)} className="bg-green-100 mt-10 py-4">
      <Menu.Item key="Mini Series" >
      <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span  >Mini Series</span>
        </div>
      </Menu.Item>
      <Menu.Item key="Small Series" >
      <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span >Small Series</span>
        </div>
      </Menu.Item>
      <Menu.Item key="Medium Series" >
      <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span >Medium Series</span>
        </div>
      </Menu.Item>
      <Menu.Item key="Large Series" >
      <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span >Large Series</span>
        </div>
      </Menu.Item>
      <Menu.Item key="EI Series" >
      <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span >EI Series</span>
        </div>
      </Menu.Item>
      <Menu.Item key="ED Series" >
      <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span >ED Series</span>
        </div>
      </Menu.Item>
      <Menu.Item key="SB Series" >
        <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span className="">SB Series</span>
        </div>
      </Menu.Item>
      <Menu.Item key="AKHG Series" >
      <div className="  ml-6 ">
          {/* <img
            className="w-[30%]"
            src="/adoniNew/home/series/largeseries.png"
            alt=""
          /> */}
          <span >AKHG Series  </span>
        </div>
      </Menu.Item>
    </Menu>
  );


  let contentComponent;
  switch (selectedMenuItem) {
    case "Mini Series":
      contentComponent = <MiniSeries />;
      break;
    case "Small Series":
      contentComponent = <SmallSeries />;
      break;
    case "Medium Series":
      contentComponent = <MediumSeries />;
      break;
    case "Large Series":
      contentComponent = <LargeSeries />;
      break;
    case "EI Series":
      contentComponent = <Eiseries />;
      break;
    case "ED Series":
      contentComponent = <EDseries />;
      break;
    case "SB Series":
      contentComponent = <SBseries />;
      break;
    case "AKHG Series":
      contentComponent = <AKHG />;
      break;
    default:
      contentComponent = null;
      break;
  }

  return (
    <>
      <Dropdown className=" md:hidden m-5" overlay={seriesMenu} trigger={["click"]}>
        <Button
          className="  text-left"
          type="link"
          style={{
            background: "blue",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Select Series
        </Button>
      </Dropdown>
      <Layout className="md:mt-20 mt-5 ">
        <Sider
          trigger={null}
          theme="light"
          collapsible
          className="!md:fixed w-[15%] md:block hidden "
        >
          {seriesMenu}
        </Sider>


        <Layout className=" md:p-0 p-2">
          <Content
            style={{
              padding: 24,
              minHeight: 280,
              background: "white",
            }}
          >
            {contentComponent}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default Industrial;
