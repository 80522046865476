import React from "react";
import styled from "styled-components";

// Styled Components
const Wrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const Heading = styled.h2`
  color: #007bff;
  font-weight: 600;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
`;

const TestimonialContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  width: 100%;
  max-width: 1500px;
`;

const TestimonialBox = styled.div`
  background-color: #fff;
  border: 2px solid #007bff;
  padding: 20px;
  width: 45%; /* Adjust to change the width of each box */
  min-width: 280px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  font-size: 1.2rem;
  color: #333;

  &:before {
    content: "“";
    padding: 10px;
    font-size: 2.5rem;
    color: #333;
    position: absolute;
    top: -2px;
    left: -4px;
  }

  @media (max-width: 768px) {
    width: 90%;
    font-size: 1rem;
  }
`;

const Author = styled.div`
  margin-top: 15px;
  font-weight: bold;
  text-align: right;
  font-size: 1rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const Testimonials = () => {
  return (
    <Wrapper>
      <Heading>What Our Customers Say</Heading>
      <TestimonialContainer>
        <TestimonialBox>
          This is for your information that M/S Adonitech has developed a Shock
          absorber as import substitute. The product has tested & found
          satisfactory.
          <Author>- Milind Kulkarni</Author>
        </TestimonialBox>
        <TestimonialBox>
          Having tested numerous shock absorbers over the years, I can
          confidently say that Adonitech's product stands out from the rest. Its
          build quality.
          <Author>- Rajesh Singh</Author>
        </TestimonialBox>
      </TestimonialContainer>
    </Wrapper>
  );
};

export default Testimonials;
