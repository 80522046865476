import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import logo1 from "../../assets/img/logo/logo1.png";
import logo2 from "../../assets/img/logo/logo2.png";
import logo3 from "../../assets/img/logo/logo3.png";
import logo4 from "../../assets/img/logo/logo4.png";
import logo5 from "../../assets/img/logo/logo5.png";
import logo6 from "../../assets/img/logo/l&t.webp";
import logo7 from "../../assets/img/logo/logo7.png";

const testimonials = [
  { id: 1, logo: logo1 },
  { id: 2, logo: logo2 },
  { id: 3, logo: logo3 },
  { id: 4, logo: logo4 },
  { id: 5, logo: logo5 },
  { id: 6, logo: logo6 },
  { id: 7, logo: logo7 },
];

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <TestimonialContainer>
      <h2 className="title">Our Partners</h2>
      <SliderWrapper>
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="testimonial-item">
              <img
                src={testimonial.logo}
                className="logo-image"
                alt={`Partner logo ${testimonial.id}`}
              />
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    </TestimonialContainer>
  );
};

// Styled components for Testimonial
const TestimonialContainer = styled.div`
  padding-bottom: 40px;
  .title {
    margin-bottom: 20px;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
  }
`;

const SliderWrapper = styled.div`
  .testimonial-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px; /* Fixed width for logos */
    height: 200px; /* Fixed height for logos */
    padding: 10px;
  }

  .logo-image {
    width: 200px;
    height: 200px;
    object-fit: contain;
    padding: 10px;
  }
`;

export default Testimonial;
