import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import banner from "../../../../../assets/img/logo/banner1.jpg";

const About = () => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowTooltip((prev) => !prev);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <StyledCard className="flex flex-col p-4 md:p-10">
      <div className="flex w-full flex-col md:flex-row">
        {/* Left side card with image */}
        <ImageWrapper>
          <div className="relative shadow-lg">
            <img src={banner} alt="Adonitech" className="h-auto w-full" />
          </div>
        </ImageWrapper>

        {/* Right side paragraph */}
        <ContentWrapper>
          <VerticalLine className="vr-line" />
          <TextContent>
            <h3 className="text-2xl font-bold text-[#2f3c44] md:text-3xl">
              About
            </h3>
            <p className="text-base text-[#2f3c44] md:text-lg">
              At Adonitech, we specialize in designing, manufacturing, and
              supplying top-tier industrial shock absorbers and vibration
              isolation equipment for automation, automotive, defense,
              pharmaceuticals, pad printing, switchgear, and crane
              manufacturing.
            </p>

            {/* Button positioned under the text */}
            <Link to="/About">
              <button className="button">Read More</button>
            </Link>
          </TextContent>
        </ContentWrapper>
      </div>
    </StyledCard>
  );
};

// Styled components
const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  padding: 0;
  max-width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  flex: 1;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
  }

  /* Add the gradient overlay */
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 50%,
      rgb(173, 183, 193) 100%
    );
    z-index: 1;
  }

  img {
    position: relative;
    z-index: 0;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column; /* Ensure content flows vertically on small screens */
  align-items: flex-start;
  background-color: rgb(173, 183, 193);
  padding: 20px;
  padding-left: 25px;

  @media (min-width: 768px) {
    flex-direction: row; /* Ensure content flows horizontally on medium and larger screens */
    padding: 40px;
    padding-left: 65px;
  }
`;

const VerticalLine = styled.div`
  border-left: 4px solid #445864; /* Darker vertical line styling */
  height: 50px; /* Adjusted height for the vertical line */
  margin-right: 10px; /* Space between line and text content */

  @media (min-width: 768px) {
    height: 150px; /* Adjusted height for the vertical line on medium and larger screens */
    margin-right: 20px; /* Space between line and text content on medium and larger screens */
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  .button {
    display: inline-block;
    padding: 7px 14px;
    border: 2px solid #445864;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    font-size: 16px;
    cursor: pointer;
    color: black;
    z-index: 1;
    margin-top: 16px; /* Space above the button */
    margin-left: 0; /* Space between the text and button */

    @media (min-width: 768px) {
      font-size: 19px;
      margin-top: 24px; /* Space above the button on medium and larger screens */
      margin-left: -24px; /* Space between the text and button on medium and larger screens */
    }
  }

  .button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #0478bf;
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button:hover {
    color: #ffffff;
    border: 1px solid #0478bf;
  }

  .button:hover:before {
    top: -35%;
    background-color: #0478bf;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }

  .button:hover:after {
    top: -45%;
    background-color: #0478bf;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }
`;

export default About;
